const EstadoTransporteCellRenderer = (props) => {
  const ESTADOS = {
    encurso: (
      <span
        style={{
          padding: '5px 15px',
          borderRadius: '3px',
          color: 'white',
          backgroundColor: '#0db030',
          fontWeight: 'bold',
        }}
      >
        En Curso
      </span>
    ),

    planificado: (
      <span
        style={{
          padding: '5px 15px',
          borderRadius: '3px',
          color: 'white',
          backgroundColor: '#3e3e3e',
          fontWeight: 'bold',
        }}
      >
        Planificado
      </span>
    ),

    despachado: (
      <span
        style={{
          padding: '5px 15px',
          borderRadius: '3px',
          color: 'white',
          backgroundColor: '#010066',
          fontWeight: 'bold',
        }}
      >
        Despachado
      </span>
    ),

    finalizado: (
      <span
        style={{
          padding: '5px 15px',
          borderRadius: '3px',
          color: 'white',
          backgroundColor: '#b4b4b4',
          fontWeight: 'bold',
        }}
      >
        Finalizado
      </span>
    ),
  };

  return ESTADOS[props.value] ?? props.value;
};

export default EstadoTransporteCellRenderer;
