export default function MapStats({ stats }) {
  return (
    <div className="d-flex">
      {stats.map((stat, statIndex) => (
        <div className="d-flex flex-column align-items-center py-2 px-3 bg-white border rounded shadow-sm ms-2 fw-bold" key={statIndex}>
          <div className="fs-2">
            <span className="">{stat.valor}</span>
          </div>
          <span className="text-uppercase fs-5">{stat.titulo}</span>
        </div>
      ))}
    </div>
  );
};
