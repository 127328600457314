import { useEffect, useState, useRef } from 'react';
import {
  Row,
  Col,
  Form,
  Button,
  InputGroup,
  Alert,
  Card,
} from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { isPossiblePhoneNumber } from 'react-phone-number-input';
import PhoneInput from 'react-phone-number-input/react-hook-form-input';
import Page from 'components/Page';
import useAxios from 'hooks/useAxios';

export default function TransportistasForm() {
  const axios = useAxios();
  const location = useLocation();
  const navigate = useNavigate();
  const telefonoRef = useRef();
  const [isSaving, setIsSaving] = useState(false);
  const [globalError, setGlobalError] = useState(null);
  const transportista = location.state?.transportista;
  const {
    handleSubmit,
    register,
    control,
    formState: { errors },
    setValue,
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      dni: null,
      nombre: null,
      telefono: null,
      empresa: null,
      patente: null,
    },
  });

  useEffect(() => {
    if (transportista) {
      setValue('telefono', transportista.telefono);
      setValue('dni', transportista.dni);
      setValue('nombre', transportista.nombre);
      setValue('empresa', transportista.empresa);
      setValue('patente', transportista.patente);
    }
  }, []);

  const onSubmit = async (data) => {
    setIsSaving(true);
    setGlobalError(null);

    try {
      if (transportista) {
        await axios.put(`/logistica/transportistas/${transportista.id}`, data);
      } else {
        await axios.post('/logistica/transportistas', data);
      }

      navigate(-1);
    } catch (ex) {
      setGlobalError(
        ex.response?.data?.message ||
          'Se produjo un error al guardar el transportista. Vuelva a intentar o consulte con el administrador.',
      );
    }

    setIsSaving(false);
  };

  return (
    <Page>
      <Page.Content>
        <Page.Header title={transportista ? 'Modificar Transportista' : 'Crear Transportista'} />

        <Form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
          <Card className="shadow-sm mb-3">
            <Card.Body>
              {globalError && <Alert variant="danger">{globalError}</Alert>}
              <Form.Group className="mb-3" as={Row} controlId="dni">
                <Form.Label column sm="2">
                  DNI
                </Form.Label>
                <Col sm="8">
                  <Form.Control
                    type="number"
                    min="1"
                    {...register('dni', { required: true })}
                    className={`form-control ${errors.dni ? 'is-invalid' : ''}`}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.dni?.type === 'required' && 'Este campo es requerido'}
                  </Form.Control.Feedback>
                </Col>
              </Form.Group>

              <Form.Group className="mb-3" as={Row} controlId="nombre">
                <Form.Label column sm="2">
                  Nombre
                </Form.Label>
                <Col sm="8">
                  <Form.Control
                    {...register('nombre', { required: true })}
                    className={`form-control ${errors.nombre ? 'is-invalid' : ''}`}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.nombre?.type === 'required' &&
                      'Este campo es requerido'}
                  </Form.Control.Feedback>
                </Col>
              </Form.Group>

              <Form.Group className="mb-3" as={Row} controlId="telefono">
                <Form.Label column sm="2">
                  Teléfono
                </Form.Label>
                <Col sm="8">
                  <InputGroup>
                    <InputGroup.Text>+54</InputGroup.Text>
                    <PhoneInput
                      name="telefono"
                      international={false}
                      defaultCountry="AR"
                      autoComplete="off"
                      control={control}
                      ref={telefonoRef}
                      rules={{
                        validate: (value) => value && isPossiblePhoneNumber(value),
                      }}
                      className={`form-control ${
                        errors.telefono ? 'is-invalid' : ''
                      }`}
                    />
                  </InputGroup>
                  <Form.Text className="text-muted">
                    Código de área sin el 0 + Número de teléfono sin el 15. Por
                    ejemplo: 11 5888-9999
                  </Form.Text>
                  <Form.Control.Feedback type="invalid" className="d-block">
                    {errors.telefono?.type === 'validate' &&
                      'El número de teléfono es incorrecto'}
                  </Form.Control.Feedback>
                </Col>
              </Form.Group>

              <Form.Group className="mb-3" as={Row} controlId="empresa">
                <Form.Label column sm="2">
                  Empresa
                </Form.Label>
                <Col sm="8">
                  <Form.Control {...register('empresa')} />
                </Col>
              </Form.Group>

              <Form.Group className="mb-3" as={Row} controlId="patente">
                <Form.Label column sm="2">
                  Patente
                </Form.Label>
                <Col sm="8">
                  <Form.Control {...register('patente')} />
                </Col>
              </Form.Group>
            </Card.Body>
          </Card>
          <div>
            <Button
              className="me-2"
              type="submit"
              variant="primary"
              disabled={isSaving}
            >
              {isSaving ? 'Guardando...' : (transportista ? 'Modificar' : 'Crear')}
            </Button>
            <Link to="/transportistas" className="btn btn-link">Cancelar</Link>
          </div>
        </Form>
      </Page.Content>
    </Page>
  );
};
