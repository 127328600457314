import React from 'react';
import ReactDOM from 'react-dom';
import dayjs from 'dayjs';
import { BrowserRouter as Router } from 'react-router-dom';
import Auth0ProviderWithHistory from './auth/Auth0ProviderWithHistory';
import App from './App';
import 'dayjs/locale/es';
import 'lato-font';
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';

dayjs.locale('es');

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <Auth0ProviderWithHistory>
        <App />
      </Auth0ProviderWithHistory>
    </Router>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
