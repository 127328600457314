import dayjs from 'dayjs';
import { Link } from 'react-router-dom';
import { Button, Modal } from 'react-bootstrap';
import { useState, useEffect } from 'react';

export const coloresEstados = {
  encurso: '#04B700',
  rechazado: '#FF2B2B',
  despachado: '#57b80b',
  otro: '#CCCCCC',
};

const descripcionesEstados = {
  encurso: 'En Curso',
  entregado: 'Entregado',
  rechazado: 'Rechazado',
  pendiente: 'Pendiente',
  cancelada: 'Cancelado',
};

const Estado = ({ estado }) => (
  <svg
    width="10"
    height="10"
    viewBox="0 0 10 10"
    xmlns="http://www.w3.org/2000/svg"
    style={{
      height: '10px',
      width: '10px',
      fill: coloresEstados[estado] ?? coloresEstados.otro,
    }}
  >
    <circle cx="5" cy="5" r="5" />
  </svg>
);

const Entrega = ({ entrega, onFinalizar }) => {
  const [showModal, setShowModal] = useState(false);

  const handleCloseModal = () => {
    setShowModal(false);
  };

  return (
    <div className="d-flex flex-column w-100" key={entrega.id}>
      <div className="d-flex align-items-start justify-items-between">
        <div style={{ width: '200px' }}>
          <div className="d-flex align-items-center">
            <div style={{ width: '30px' }}>
              <Estado estado={entrega.estado} />
            </div>
            <div className="d-flex justify-content-between align-items-center w-100">
              <div>
                Entrega Nº{' '}
                <Link to={`/entregas?codigo=${entrega.codigo}`}>
                  {entrega.codigo}
                </Link>
              </div>
            </div>
          </div>
          <div
            style={{
              borderLeft: 'dashed #cccccc 1px',
              marginLeft: '4px',
              paddingLeft: '23px',
              fontSize: '.8rem',
              color: '#8b8b8b',
              paddingBottom: '1rem',
            }}
          >
            <div>Destinatario: {entrega.destinatario?.nombre}</div>
            <div>
              Cantidad de bolsas:{' '}
              {entrega.detalle
                .map((el) => el.cantidad)
                .reduce((prev, current) => prev + current, 0)}
            </div>

            {entrega.estado === 'encurso' && (
              <div>
                <button
                  type="button"
                  style={{
                    cursor: 'pointer',
                    border: 'none',
                    background: 'none',
                    color: 'blue',
                    padding: '0',
                  }}
                  onClick={() => setShowModal(true)}
                >
                  Finalizar sin remito
                </button>
                <Modal
                  onHide={handleCloseModal}
                  show={showModal}
                  backdrop
                  centered
                >
                  <Modal.Header closeButton>
                    <Modal.Title>
                      Finalizar Entrega {entrega.codigo}
                    </Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    Se finalizara la entrega <strong>{entrega.codigo}</strong>{' '}
                    sin remito.
                  </Modal.Body>
                  <Modal.Footer>
                    <Button
                      variant="outline-secondary"
                      onClick={handleCloseModal}
                    >
                      Cancelar
                    </Button>
                    <Button
                      variant="primary"
                      onClick={() => {
                        onFinalizar && onFinalizar(entrega);
                        handleCloseModal();
                      }}
                    >
                      Finalizar
                    </Button>
                  </Modal.Footer>
                </Modal>
              </div>
            )}
          </div>
        </div>

        <span
          className="text-white rounded-pill text-uppercase py-1 px-3"
          style={{
            background: '#404040',
            fontSize: '.6rem',
          }}
        >
          {descripcionesEstados[entrega.estado]}
        </span>
      </div>
    </div>
  );
};

const Localidad = ({ localidadConEntregas, onFinalizar }) => {
  return (
    <div>
      <div
        style={{ backgroundColor: '#F3F7FF' }}
        className="ps-3 pt-3 d-flex align-items-center my-2 me-1"
      >
        <p className="d-flex align-items-center">
          Localidad:
          <span className="ps-2" style={{ color: '#5595F8' }}>
            {localidadConEntregas.nombre}
          </span>
        </p>
      </div>

      <div className="px-3">
        {localidadConEntregas.entregas.map((entrega) => (
          <Entrega
            entrega={entrega}
            onFinalizar={onFinalizar}
            key={entrega.id}
          />
        ))}
      </div>
    </div>
  );
};

export default function TransporteTabEntregas({ transporte, onFinalizar }) {
  const [eta, setEta] = useState('');
  const [localidades, setLocalidades] = useState([]);

  useEffect(() => {
    if (transporte.estado !== 'finalizado') {
      const hayEntregasEnCurso = transporte.entregas.some(
        (e) => e.estado === 'encurso' || e.estado === 'pendiente',
      );

      if (hayEntregasEnCurso) {
        const ultimaEntrega = transporte.entregas[transporte.entregas.length - 1];

        if (ultimaEntrega?.entrega_estimada_el) {
          setEta(
            dayjs(ultimaEntrega.entrega_estimada_el)
              .fromNow()
              .replace('en', '')
          );
        }
      }
    }

    const localidadesUnicas = new Set(
      transporte.entregas.map(
        (entrega) => entrega.destinatario.localidad.nombre
      )
    );

    setLocalidades(
      [...localidadesUnicas].map((localidad) => ({
        nombre: localidad,
        entregas: transporte.entregas.filter(
          (entrega) => entrega.destinatario.localidad.nombre === localidad
        ),
      }))
    );
  }, [transporte])

  return (
    <>
      <div className="mb-2">
        <div>Tiempo restante del viaje (ETA)</div>
        <div className="text-primary">{`${eta}`}</div>

        <div className='d-flex flex-column gap-3'>
          {localidades.map((localidadConEntregas, index) => (
            <Localidad
              localidadConEntregas={localidadConEntregas}
              onFinalizar={onFinalizar}
              key={`Localidad-${index + 1}`}
            />
          ))}
        </div>
      </div>
    </>
  );
}
