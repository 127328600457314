import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Button, Modal } from 'react-bootstrap';
import Page from 'components/Page';
import Loading from 'components/Loading';
import TablePagination from 'components/TablePagination';
import useAxios from 'hooks/useAxios';

const columns = [
  {
    headerName: 'Nombre',
    field: 'nombre',
  },
  {
    headerName: 'DNI',
    field: 'dni',
  },
  {
    headerName: 'Teléfono',
    field: 'telefono',
  },
  {
    headerName: 'Empresa',
    field: 'empresa',
  },
  {
    headerName: 'Patente',
    field: 'patente',
  },
];

export default function Transportistas() {
  const axios = useAxios();
  const [isLoading, setIsLoading] = useState(false);
  const [transportistas, setTransportistas] = useState([]);
  const [transportista, setTransportista] = useState(null);
  const [newPassword, setNewPassword] = useState(null);
  const navigate = useNavigate();

  const fetchData = async () => {
    setIsLoading(true);

    try {
      const response = await axios.get('/logistica/transportistas');
      setTransportistas(response.data);
    } catch (error) {
      console.error(error);
    }

    setIsLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleCloseResetModal = () => {
    setTransportista(null);
  };

  const handleCloseNewPasswordModal = () => {
    setNewPassword(null);
  };

  const handleResetPin = async () => {
    try {
      const response = await axios.put(`/logistica/transportistas/${transportista.id}/pin`);
      setNewPassword(response.data.nuevo_pin);
      setTransportista(null);
    } catch (error) {
      console.error(error);
    }
  };

  const handleReset = (transportista) => {
    setTransportista(transportista);
  };

  const handleEdit = (transportista) => {
    navigate('/transportistas/editar', {
      state: { transportista },
    });
  };

  return (
    <>
      <Page>
        {isLoading && <Loading />}

        <Page.Content>
            <Page.Header title="Transportistas">
              <Link to="/transportistas/crear">
                Añadir Nuevo Transportista
              </Link>
            </Page.Header>

            <div className="flex-grow-1">
              <TablePagination
                columns={columns}
                data={transportistas}
                onResetPin={handleReset}
                onEdit={handleEdit}
                pagination
              />
            </div>
        </Page.Content>
      </Page>

      {transportista && (
        <Modal
          onHide={handleCloseResetModal}
          show={transportista}
          backdrop
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>Reseteo de PIN</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Se le restablecerá el PIN inicial al transportista{' '}
            <strong>{transportista.nombre}</strong>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="outline-secondary" onClick={handleCloseResetModal}>
              Cancelar
            </Button>
            <Button variant="primary" onClick={handleResetPin}>
              Resetear PIN
            </Button>
          </Modal.Footer>
        </Modal>
      )}

      {newPassword && (
        <Modal
          onHide={handleCloseNewPasswordModal}
          show={newPassword}
          backdrop
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>Nuevo PIN</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Se cambió el PIN correctamente. El nuevo PIN es:{' '}
            <strong>{newPassword}</strong>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={handleCloseNewPasswordModal}>
              Aceptar
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </>
  );
};
