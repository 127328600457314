import { useAuth0 } from '@auth0/auth0-react';
import { useEffect } from 'react';
import axios from '../api/axios';

export default function useAxios() {
  const { getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    const requestIntercept = axios.interceptors.request.use(
      (config) => {
        const token = window.localStorage.getItem('token');

        if (token) {
          config.headers['Authorization'] = `Bearer ${token}`;
        }

        return config;
      },
      (error) => Promise.reject(error),
    );

    const responseIntercept = axios.interceptors.response.use(
      (response) => response,
      async (error) => {
        const prevRequest = error?.config;

        if (error?.response?.status === 401 && !prevRequest?.sent) {
          const token = await getAccessTokenSilently();

          window.localStorage.setItem('token', token);
          prevRequest.headers['Authorization'] = `Bearer ${token}`;

          return axios(prevRequest);
        }

        return Promise.reject(error);
      },
    );

    return () => {
      axios.interceptors.request.eject(requestIntercept);
      axios.interceptors.response.eject(responseIntercept);
    };
  }, [])

  return axios;
}
