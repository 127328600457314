import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import config from '../config';

const Demora = ({ demora, onDelete }) => {
  const [showModal, setShowModal] = useState(false);
  const [disabled, setDisabled] = useState(false);

  useEffect(() => {
    const segundosTranscurridos = dayjs().diff(dayjs(demora.creado_el), 's');
    const segundosRestantes = config.segundosParaEliminarDemora - segundosTranscurridos;

    if (segundosRestantes > 0) {
      const timeOut = setTimeout(() => {
        setDisabled(true);
      }, segundosRestantes * 1000);

      return () => clearTimeout(timeOut);
    }

    setDisabled(true);
  }, []);

  const handleCloseModal = () => {
    setShowModal(false);
  };

  return (
    <>
      <div className="d-flex justify-content-between align-items-start">
        <div>
          <strong>Demora +{demora.horas}</strong>
          <div>
            <span className="text-muted">{demora.motivo.descripcion}</span>{' '}
            <strong>{dayjs(demora.creado_el).format('HH:mm')}</strong>
          </div>
        </div>
        <Button
          size="sm"
          variant="outline-danger"
          onClick={() => setShowModal(true)}
          disabled={disabled}
        >
          Eliminar
        </Button>
      </div>
      <Modal
        onHide={handleCloseModal}
        show={showModal}
        backdrop
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Eliminar Demora</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Si elimina la demora, no se enviará la notificación al distribuidor.
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-secondary" onClick={handleCloseModal}>
            Cancelar
          </Button>
          <Button
            variant="danger"
            onClick={() => {
              onDelete && onDelete(demora);
              setShowModal(false);
            }}
          >
            Eliminar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default function TransporteTabDemoras({ demoras, onDelete }) {
  if (demoras.length === 0) {
    return <div className="text-muted text-center">Sin demoras</div>
  }

  return demoras.map((demora) => <Demora key={demora.id} demora={demora} onDelete={onDelete} />);
};
