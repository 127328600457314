import { useRef, useState } from "react";
import html2canvas from "html2canvas";
import ReactApexChart from "react-apexcharts";
import utils from 'utils';
import DashboardCard from "./DashboardCard";
import { ReactComponent as DownloadIcon } from 'assets/download.svg';
import { ReactComponent as GirasolIcon } from 'assets/girasol.svg';
import { ReactComponent as MaizIcon } from 'assets/maiz.svg';

const themes = {
  nk: ['#2E966D', '#235190', '#A5A5A5'],
  nd: ['#FFD100', '#391E11', '#A5A5A5'],
  sps: ['#006848', '#46be3c', '#54e000'],
};

const cultivoIcons = {
  maiz: MaizIcon,
  girasol: GirasolIcon,
};

export default function ChartWidget({ title, icon, theme, type, categories, series, mixed = false }) {
  const CultivoIcon = icon ? cultivoIcons[icon] : null;
  const colors = theme ? themes[theme.toLowerCase()] : ['#F3AC3C', '#3063D1', '#A5A5A5'];
  const ref = useRef();

  const handleDownload = async (e) => {
    const button = e.currentTarget;

    button.style.display = 'none';

    const canvas = await html2canvas(ref.current);

    button.style.display = 'block';

    const link = document.createElement('a');

    link.href = canvas.toDataURL();
    link.download = `${title}.png`;

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <DashboardCard className="col">
      <div ref={ref}>
        <div className="d-flex justify-content-between align-items-center">
          <DashboardCard.Title>
            {title}
            {CultivoIcon && (
              <CultivoIcon
                style={{
                  width: '32px',
                  marginLeft: '5px',
                  color: theme?.toLowerCase() === 'nk' ? '#2E966D' : '#FFD100'
                }}
              />
            )}
          </DashboardCard.Title>
          <button
            type="button"
            className="btn btn-sm btn-light"
            onClick={handleDownload}
          >
            <DownloadIcon />
          </button>
        </div>
        <ReactApexChart
          height="350"
          className="mt-4"
          series={series}
          type={type}
          options={{
            chart: {
              toolbar: {
                show: false,
              },
            },
            plotOptions: {
              bar: {
                dataLabels: {
                  position: mixed ? 'center' : 'top',
                  orientation: 'vertical',
                },
              },

            },
            dataLabels: {
              enabled: true,
              style: {
                colors: mixed ? undefined : colors,
              },
              offsetY: mixed ? 0 : 10,
              formatter: (val) => {
                if (val > 0) {
                  return utils.formatNumber(val);
                }
              },
              background: {
                enabled: mixed,
                borderWidth: 0,
              },
            },
            xaxis: {
              categories,
            },
            yaxis: mixed ? [
              {
                labels: {
                  formatter: (val) => utils.formatNumber(val),
                },
              },
              {
                opposite: true,
                labels: {
                  formatter: (val) => utils.formatNumber(val),
                },
              },
            ] : {
              labels: {
                formatter: (val) => utils.formatNumber(val),
              },
            },
            tooltip: {
              enabled: false,
            },
            colors,
            fill: {
              opacity: 1,
            },
            }}
        />
      </div>
    </DashboardCard>
  );
}
