import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import {
  Row,
  Col,
  Form,
  Button,
  Alert,
  Card,
} from 'react-bootstrap';
import Page from 'components/Page';
import Loading from 'components/Loading';
import useAxios from 'hooks/useAxios';

export default function DestinatariosForm() {
  const axios = useAxios();
  const navigate = useNavigate();
  const location = useLocation();
  const destinatario = location.state?.destinatario;
  const [isSaving, setIsSaving] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [globalError, setGlobalError] = useState(null);
  const [provincias, setProvincias] = useState([]);
  const [distribuidores, setDistribuidores] = useState([]);
  const [localidades, setLocalidades] = useState([]);
  const {
    handleSubmit,
    register,
    formState: { errors },
    setValue,
    watch,
  } = useForm({ mode: 'onChange' });
  const provincia = watch('provincia');

  const fetchProvincias = async () => {
    try {
      const response = await axios.get('/logistica/provincias/select');
      setProvincias(response.data);
    } catch (error) {
      console.error(error)
    }
  };

  const fetchDistribuidores = async () => {
    try {
      const response = await axios.get('/logistica/distribuidores/select');
      setDistribuidores(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchData = async () => {
    setIsLoading(true);

    await Promise.all([
      fetchProvincias(),
      fetchDistribuidores(),
    ]);

    if (destinatario) {
      setValue('distribuidor_id', destinatario.distribuidor.id);
      setValue('codigo', destinatario.codigo);
      setValue('nombre', destinatario.nombre);
      setValue('lat', destinatario.geolocalizacion?.lat ?? null);
      setValue('lng', destinatario.geolocalizacion?.lng ?? null);
      setValue('direccion', destinatario.direccion ?? null);
      setValue('provincia', destinatario.localidad.provincia);
    }

    setIsLoading(false);
  }

  useEffect(() => {
    if (provincia) {
      const _provincia = provincias.find((p) => p.nombre.toLowerCase() === provincia.toLowerCase());
      setLocalidades(_provincia?.localidades ?? []);
    }
  }, [provincia]);

  useEffect(() => {
    setValue('localidad_id', destinatario?.localidad?.id ?? null);
  }, [localidades])

  useEffect(() => {
    fetchData();
  }, []);

  const onSubmit = async (data) => {
    setIsSaving(true);
    setGlobalError(null);

    delete data.provincia;

    try {
      if (destinatario) {
        await axios.put(
          `/logistica/destinatarios/${destinatario.id}`,
          data,
        );
      } else {
        await axios.post('/logistica/destinatarios', data);
      }

      navigate('/destinatarios');
    } catch (ex) {
      setGlobalError(
        ex.response?.data?.message ||
          'Se produjo un error al guardar el destinatario. Vuelva a intentar o consulte con el administrador.',
      );
    }

    setIsSaving(false);
  };

  return (
    <Page>
      {isLoading && <Loading />}

      <Page.Content>
        <Page.Header title={destinatario ? 'Modificar Destinatario' : 'Crear Destinatario'} />

        <Form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
          <Card className="shadow-sm mb-3">
            <Card.Body>
              {globalError && <Alert variant="danger">{globalError}</Alert>}

              <Form.Group as={Row} className="mb-3" controlId="distribuidor">
                <Form.Label column sm="2">
                  Nombre Distribuidor
                </Form.Label>
                <Col sm="8">
                  <Form.Select
                    {...register('distribuidor_id', { required: true })}
                    className={`form-control ${errors.distribuidor_id ? 'is-invalid' : ''}`}
                  >
                    <option></option>
                    {distribuidores.map((distribuidor, index) => {
                      return (
                        <option key={index} value={distribuidor.id}>
                          {`${distribuidor.nombre} (${distribuidor.marca})`}
                        </option>
                      );
                    })}
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    Este campo es requerido
                  </Form.Control.Feedback>
                </Col>
              </Form.Group>

              <Form.Group as={Row} className="mb-3" controlId="codigo">
                <Form.Label column sm="2">
                  Nº Destinatario
                </Form.Label>
                <Col sm="8">
                  <Form.Control
                    type="number"
                    {...register('codigo', { required: true })}
                    className={errors.codigo ? 'is-invalid' : ''}
                  />
                  <Form.Control.Feedback type="invalid">
                    Este campo es requerido
                  </Form.Control.Feedback>
                </Col>
              </Form.Group>
              <Form.Group as={Row} className="mb-3" controlId="nombre">
                <Form.Label column sm="2">
                  Nombre Destinatario
                </Form.Label>
                <Col sm="8">
                  <Form.Control
                    {...register('nombre', { required: true })}
                    className={errors.nombre ? 'is-invalid' : ''}
                  />
                  <Form.Control.Feedback type="invalid">
                    Este campo es requerido
                  </Form.Control.Feedback>
                </Col>
              </Form.Group>
              <Form.Group as={Row} className="mb-3" controlId="direccion">
                <Form.Label column sm="2">
                  Dirección
                </Form.Label>
                <Col sm="8">
                  <Form.Control
                    {...register('direccion', { required: true })}
                    className={errors.direccion ? 'is-invalid' : ''}
                  />
                  <Form.Control.Feedback type="invalid">
                    Este campo es requerido
                  </Form.Control.Feedback>
                </Col>
              </Form.Group>
              <Form.Group as={Row} className="mb-3" controlId="provincia">
                <Form.Label column sm="2">
                  Provincia
                </Form.Label>
                <Col sm="8">
                  <Form.Select
                    {...register('provincia', { required: true })}
                    className={errors.provincia ? 'is-invalid' : ''}
                  >
                    <option></option>
                    {provincias.map((provincia, index) => {
                      return (
                        <option key={index} value={provincia.nombre}>
                          {provincia.nombre}
                        </option>
                      );
                    })}
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    Este campo es requerido
                  </Form.Control.Feedback>
                </Col>
              </Form.Group>
              <Form.Group as={Row} className="mb-3" controlId="localidad">
                <Form.Label column sm="2">
                  Localidad
                </Form.Label>
                <Col sm="8">
                  <Form.Select
                    {...register('localidad_id', { required: true })}
                    className={errors.localidad_id ? 'is-invalid' : ''}
                  >
                    <option></option>
                    {localidades.map((localidad, index) => {
                      return (
                        <option key={index} value={localidad.id}>
                          {localidad.nombre}
                        </option>
                      );
                    })}
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    Este campo es requerido
                  </Form.Control.Feedback>
                </Col>
              </Form.Group>
              <Form.Group as={Row} className="mb-3" controlId="geolocalizacion">
                <Form.Label column sm="2">
                  Geolocalización
                </Form.Label>
                <Col sm="8">
                  <Row>
                    <Form.Label column sm="2">
                      Latitud
                    </Form.Label>
                    <Col sm="4">
                      <Form.Control
                        {...register('lat')}
                        className={errors.lat ? 'is-invalid' : ''}
                      />
                      <Form.Control.Feedback type="invalid">
                        Este campo es requerido
                      </Form.Control.Feedback>
                    </Col>
                    <Form.Label column sm="2">
                      Longitud
                    </Form.Label>
                    <Col sm="4">
                      <Form.Control
                        {...register('lng')}
                        className={errors.lng ? 'is-invalid' : ''}
                      />
                      <Form.Control.Feedback type="invalid">
                        Este campo es requerido
                      </Form.Control.Feedback>
                    </Col>
                  </Row>
                </Col>
              </Form.Group>
            </Card.Body>
          </Card>
          <div>
            <Button
              className="me-2"
              type="submit"
              variant="primary"
              disabled={isSaving}
            >
              {isSaving ? 'Guardando...' : (destinatario ? 'Modificar' : 'Crear')}
            </Button>
            <Link to="/destinatarios" className="btn btn-link">Cancelar</Link>
          </div>
        </Form>
      </Page.Content>
    </Page>
  );
};
