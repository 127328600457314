import DashboardCard from "./DashboardCard";

export default function ValueWidget({ widget }) {
  return (
    <DashboardCard className="col">
      <div className="d-flex gap-2 justify-content-between align-items-start mb-2">
        <DashboardCard.Title>
          {widget.title}
        </DashboardCard.Title>
        {widget.type && <DashboardCard.Tag type={widget.type} />}
      </div>

      <div style={{ color: widget.color || 'black' }} className="fs-1">
        {widget.formattedValue}
      </div>

      {widget.trend && (
        <h5 className="text-uppercase m-0">
          <span style={{ color: widget.trend.color || 'black' }}>
            {widget.trend.formattedValue}
          </span>{' '}
          <span className="text-muted">{widget.trend.text}</span>
        </h5>
      )}
    </DashboardCard>
  );
}
