import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import CamionNk from 'assets/perfil_camion_nk.png';
import CamionNidera from 'assets/perfil_camion_nidera.png';
import CamionSps from 'assets/perfil_camion_sps.png';
import config from '../config';

dayjs.extend(relativeTime);

const backgroundsEstados = {
  finalizado: 'bg-secondary',
  online: 'bg-success',
  offline: 'bg-danger',
};

export default function TransporteHeader({ transporte }) {
  const marca = transporte.marca.toUpperCase();
  let estado = '';

  if (transporte.estado === 'finalizado') {
    estado = 'finalizado';
  } else {
    const horasDesdeUltimaPosicion = dayjs().diff(
      dayjs(transporte.ultima_geolocalizacion_el),
      'h',
    );

    if (horasDesdeUltimaPosicion >= config.horasOffline) {
      estado = 'offline';
    } else {
      estado = 'online';
    }
  }

  return (
    <div className="py-2 px-3 border rounded shadow-sm">
      <div className="row align-items-center">
        <div className="col d-flex flex-column align-items-center">
          <img
            className="img-fluid"
            src={marca === 'NK' ? CamionNk : marca === 'SPS' ? CamionSps : CamionNidera}
          />
          <strong>{marca === 'ND' ? 'Nidera' : marca}</strong>
        </div>
        <div className="col d-flex flex-column align-items-center">
          <div className="fs-3 fw-bold text-center text-nowrap">
            {transporte.codigo}
          </div>
          <div className={`py-1 text-center text-white fw-bold rounded-pill w-100 ${backgroundsEstados[estado]}`}>
            {estado.toUpperCase()}
          </div>
        </div>
      </div>
    </div>
  )
};
