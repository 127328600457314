const DashboardCard = ({ children, className }) => {
  return (
    <div className={`p-3 rounded shadow-sm bg-white ${className}`}>
      <div className="d-flex flex-column">
        {children}
      </div>
    </div>
  );
};


DashboardCard.Title = ({ children }) => (
  <h4 className="d-flex align-items-center m-0 fw-bold text-dark">{children}</h4>
);

DashboardCard.Tag = ({ type }) => {
  const types = {
    monthly: {
      title: 'Mensual',
      color: '#8F7600',
      background: '#FFD10033',
    },
    campaign: {
      title: 'Campaña',
      color: '#235190',
      background: '#23519033',
    },
  };

  const color =  types[type]?.color || '#777F88';
  const background = types[type]?.background || '#777F8833';
  const label = types[type]?.title || type;

  return (
    <div className="px-3 rounded" style={{ color, background }}>
      <p className="fw-bold m-0">{label}</p>
    </div>
  );
};

export default DashboardCard;
