import { useEffect, useState } from 'react';
import Filter from './Filter';

export default function FilterGroup({ filters, selectedOptions, onClean, onApply }) {
  const [showClean, setShowClean] = useState(false);

  useEffect(() => {
    setShowClean(
      Object.keys(selectedOptions)
        .filter((key) => key !== 'historico')
        .map((key) => selectedOptions[key].length)
        .reduce((prev, curr) => prev + curr, 0) > 0
    );
  }, [selectedOptions]);

  return (
    <div className="d-flex gap-3">
      {showClean && (
        <button type="button" className="btn btn-sm btn-outline-danger" onClick={onClean}>
          Limpiar filtros
        </button>
      )}
      {Object.keys(filters).map((key) => (
        <Filter
          key={key}
          defaultTitle={filters[key].title}
          defaultSelectedOptions={selectedOptions[key] ?? []}
          options={filters[key].options}
          onApply={(selected) => onApply(key, selected)}
        />
      ))}
    </div>
  );
};
