import { Link } from 'react-router-dom';

const NroTransporteCellRenderer = (props) => {
  return (
    <Link
      to={`/transportes/${props.data.transporte_id}`}
      target="_blank"
      rel="noreferrer"
    >
      {props.value}
    </Link>
  );
};

export default NroTransporteCellRenderer;
