import DashboardCard from "./DashboardCard";

const Month = ({ name, diff }) => (
  <div className="d-flex flex-column justify-content-center align-items-center">
    <div style={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '20px',
      width: '20px',
      border: `${diff < 0 ? '5px' : '1px'} solid ${diff > 0 ? '#ddd' : 'green'}`,
      borderRadius: '100%'
    }}>
      {diff === 0 && (
        <div style={{
          height: '12px',
          width: '12px',
          border: '1px solid green',
          borderRadius: '100%',
        }}></div>
      )}
    </div>
    <div className="mt-1" style={{ fontSize: '.8rem', color: '#bbb' }}>
      {name}
    </div>
  </div>
);

export default function TimelineWidget({ widget, ytd }) {
  return (
    <DashboardCard className="col">
      <DashboardCard.Title>
        {ytd ? `${widget.title}: ${ytd.formattedValue} [YTD]` : widget.title}
      </DashboardCard.Title>
      <div className="d-flex gap-2 mt-2">
        <div
          className="d-flex flex-column align-items-end"
          style={{
            fontSize: '.8rem',
            color: '#bbb',
          }}
        >
          <div
            className="d-flex align-items-center"
            style={{ height: '30px' }}
          >
            Actual
          </div>
          <div
            className="d-flex align-items-center"
            style={{ height: '25px' }}
          >
            Anterior
          </div>
        </div>

        {Object.keys(widget.years).map((year) => (
          <div key={year} className="d-flex flex-column gap-2" style={{ width: `${widget.years[year].length * 8}%`}}>
            <div className="d-flex align-items-center justify-content-center">
              {widget.years[year].map((item, index) => (
                <div
                  key={index}
                  className="d-flex flex-column gap-1 justify-content-around align-items-center h-100 w-100"
                >
                  <div
                    className="d-flex justify-content-center align-items-center fs-5 fw-bold"
                    style={{ height: '30px' }}
                  >
                    {item.formattedValue}
                  </div>
                  <div
                    className={`d-flex justify-content-center align-items-center rounded w-75 ${item.diff === 0 && 'border'}`}
                    style={{
                      height: '25px',
                      fontSize: '.8rem',
                      backgroundColor: item.diff < 0 && '#F1F1F1',
                    }}
                  >
                    {item.previousFormattedValue === null ? '-' : item.previousFormattedValue}
                  </div>
                  <Month name={item.monthName} diff={item.diff} />
                </div>
              ))}
            </div>
            <div
              className="fw-bold p-1 rounded text-center"
              style={{
                backgroundColor: '#F2F2F2',
                color: '#B9B9B9'
              }}
            >
              {year}
            </div>
          </div>
        ))}
      </div>
    </DashboardCard>
  );
}
