import { Container } from 'react-bootstrap';
import NavBar from './NavBar';

const Page = ({ children }) => {
  return (
    <div className="d-flex flex-column" style={{ height: '100vh' }}>
      <NavBar />
      <div className="flex-grow-1">
        {children}
      </div>
    </div>
  );
};

Page.Content = ({ children }) => (
  <Container fluid className="py-3 h-100">
    <div className="d-flex flex-column h-100">
      {children}
    </div>
  </Container>
);

Page.Header = ({ children, title }) => (
  <div className="d-flex justify-content-between align-items-center mb-2">
    <h5 className="m-0">{title}</h5>
    {children}
  </div>
);

export default Page;
