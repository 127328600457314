import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Button, Modal, FormCheck } from 'react-bootstrap';
import styled from 'styled-components';
import dayjs from 'dayjs';
import MapStats from 'components/MapStats';
import CrearDemora from 'components/CrearDemora';
import NavBar from 'components/NavBar';
import Loading from 'components/Loading';
import TransporteHeader from 'components/TransporteHeader';
import TransporteTabEntregas from 'components/TransporteTabEntregas';
import TransporteTabDemoras from 'components/TransporteTabDemoras';
import useMap from 'hooks/useMap';
import utils from 'utils';
import useAxios from 'hooks/useAxios';

const TabButton = styled.button`
  background: none;
  width: 50%;
  padding: 1rem 0;
  font-size: 14px;
  font-weight: bold;
  color: #888;
  border: 0;
  border-bottom: solid transparent 4px;
  &.active {
    border-bottom: solid #050264 4px;
  }
  &:hover {
    background: #ddd;
  }
`;

const geojson = {
  type: 'FeatureCollection',
  features: [
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [-77.032, 38.913],
      },
      properties: {
        title: 'Mapbox',
        description: 'Washington, D.C.',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [-122.414, 37.776],
      },
      properties: {
        title: 'Mapbox',
        description: 'San Francisco, California',
      },
    },
  ],
};

export default function Transporte() {
  const axios = useAxios();
  const { transporteId } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [showFinalizarModal, setShowFinalizarModal] = useState(false);
  const [showFinalizar, setShowFinalizar] = useState(false);
  const [transporte, setTransporte] = useState(null);
  const [motivos, setMotivos] = useState([]);
  const [demoras, setDemoras] = useState([]);
  const [selectedTab, setSelectedTab] = useState(1);
  const map = useMap();

  const fetchDemoras = async () => {
    try {
      const response = await axios.get(`/logistica/transportes/${transporteId}/demoras`);
      setDemoras(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchMotivos = async () => {
    try {
      const response = await axios.get('/logistica/transportes/demoras/motivos');
      setMotivos(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchTransporte = async () => {
    try {
      const response = await axios.get(`/logistica/transportes/${transporteId}`);

      if (response.data.estado === 'finalizado') {
        setShowFinalizar(false);
      } else {
        const hayEntregasEnCurso = response.data.entregas.some(
          (e) => e.estado === 'encurso' || e.estado === 'pendiente',
        );

        setShowFinalizar(!hayEntregasEnCurso);
      }

      setTransporte(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  const handleCrearDemora = async ({ motivoId, horas }) => {
    try {
      const response = await axios.post(
        `/logistica/transportes/${transporteId}/demoras`,
        {
          motivo_demora_id: motivoId,
          horas,
        },
      );

      fetchDemoras();
    } catch (error) {
      console.error(error);
      alert('No se pudo crear la demora');
    }
  };

  const handleEliminarDemora = async (demora) => {
    try {
      const response = await axios.delete(
        `/logistica/transportes/${transporteId}/demoras/${demora.id}`
      );

      fetchDemoras();
    } catch (error) {
      console.error(error);
      alert('No se pudo eliminar la demora');
    }
  };

  const handleCloseFinalizarModal = () => {
    setShowFinalizarModal(false);
  };

  const handleFinalizarTransporte = async () => {
    try {
      const response = await axios.put(
        `/logistica/transportes/${transporteId}/finalizar`,
      );

      fetchTransporte();
      handleCloseFinalizarModal();
    } catch (error) {
      console.error(error);
    }
  };

  const handleFinalizarEntrega = async (entrega) => {
    try {
      const response = await axios.post(
        `/logistica/entregas/${entrega.id}/finalizar`,
        {
          entregado_el: dayjs().format('YYYY-MM-DD HH:mm:ss'),
        },
      );

      fetchTransporte();
    } catch (error) {
      console.error(error);
      alert('No se pudo finalizar la entrega');
    }
  };

  useEffect(() => {
    fetchMotivos();
    fetchTransporte();
  }, []);

  useEffect(() => {
    if (transporte) {
      fetchDemoras();
    }

    if (map.isLoaded && transporte) {
      utils.dibujarTransportes(map, [transporte]);
      map.flyTo(utils.ubicacionTransporte(transporte), 14);
    }
  }, [transporte, map.isLoaded]);

  return (
    <>
      {isLoading && <Loading />}

      <div className="position-relative" style={{ zIndex: 2 }}>
        <NavBar />
      </div>

      {transporte && (
        <>
          <div
            className="d-flex flex-column shadow-sm p-3 bg-white rounded position-absolute"
            style={{
              zIndex: 3,
              width: '360px',
              top: '80px',
              bottom: '20px',
              left: '20px',
            }}
          >
            <div className="mb-3">
              <TransporteHeader transporte={transporte} />
            </div>

            <div className="h-100 overflow-auto">
              {selectedTab === 1 && (
                <TransporteTabEntregas
                  transporte={transporte}
                  onFinalizar={handleFinalizarEntrega}
                />
              )}
              {selectedTab === 2 && (
                <TransporteTabDemoras
                  demoras={demoras}
                  onDelete={handleEliminarDemora}
                />
              )}
            </div>

            <div className="d-grid gap-3 mt-3">
              <FormCheck
                id="show-tracking"
                label="Mostrar tracking"
                onClick={(event) =>
                  map.showLayer(
                    `tracking-${transporte.id}`,
                    event.target.checked,
                  )
                }
              />

              {showFinalizar && (
                <Button
                  variant="primary"
                  onClick={() => setShowFinalizarModal(true)}
                >
                  Finalizar
                </Button>
              )}
            </div>

            <div className="d-flex">
              <TabButton
                type="button"
                className={selectedTab === 1 ? 'active' : ''}
                onClick={() => setSelectedTab(1)}
              >
                Detalle del Viaje
              </TabButton>
              <TabButton
                type="button"
                className={selectedTab === 2 ? 'active' : ''}
                onClick={() => setSelectedTab(2)}
              >
                Demoras
              </TabButton>
            </div>
          </div>

          <div
            className="position-absolute"
            style={{ zIndex: 2, top: '80px', right: '20px' }}
          >
            <CrearDemora motivos={motivos} onCreate={handleCrearDemora} />
          </div>

          <div
            className="d-flex justify-content-end position-absolute"
            style={{ zIndex: 2, right: '50px', bottom: '20px' }}
          >
            <MapStats stats={transporte.dashboard} />
          </div>
        </>
      )}

      <div className="mapa" ref={map.containerRef}></div>

      {showFinalizarModal && (
        <Modal
          onHide={handleCloseFinalizarModal}
          show={showFinalizarModal}
          backdrop
          centered
        >
          <Modal.Header>
            <Modal.Title>Finalizar Transporte {transporte.codigo}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Se finalizara el transporte <strong>{transporte.codigo}</strong>.
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="outline-secondary"
              onClick={handleCloseFinalizarModal}
            >
              Cancelar
            </Button>
            <Button variant="primary" onClick={handleFinalizarTransporte}>
              Finalizar
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </>
  );
}
