export default {
  apiUrl: process.env.REACT_APP_API_URL,

  tokenMapbox: process.env.REACT_APP_MAPBOX_ACCESS_TOKEN,

  auth0: {
    domain: process.env.REACT_APP_AUTH0_DOMAIN,
    clientId: process.env.REACT_APP_AUTH0_CLIENTID,
    audience: process.env.REACT_APP_AUTH0_AUDIENCE,
    namespace: process.env.REACT_APP_AUTH0_NAMESPACE,
  },

  horasOffline: 3,

  geolocalizacionDespachoVenadoTuerto: {
    lat: -33.742081116121525,
    lng: -61.97806933064846,
  },

  mapBoxStyle: 'mapbox://styles/encamino2021/ckqquj9kl48oe18mfqgxuy3cv',

  segundosParaEliminarDemora: 10 * 60,
};
