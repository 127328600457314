import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Container } from 'react-bootstrap';
import Loading from 'components/Loading';
import useAxios from 'hooks/useAxios';

const DownloadEntrega = () => {
  const axios = useAxios();
  const params = useParams();
  const [downloading, setDownloading] = useState(false);
  const [codigoEntrega, setCodigoEntrega] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const download = async () => {
    setDownloading(true);
    try {
      const response = await axios.get(
        `/logistica/entregas/download?codigo=${codigoEntrega}`,
        { responseType: 'blob' },
      );

      const href = window.URL.createObjectURL(response.data);
      const anchor = document.createElement('a');

      anchor.href = href;
      anchor.setAttribute(
        'download',
        response.headers['x-suggested-filename'] ?? 'Remitos',
      );
      anchor.click();

      window.URL.revokeObjectURL(href);
    } catch (e) {
      if (e.response?.data) {
        const text = await e.response.data.text();
        const data = JSON.parse(text);

        if (data.message) {
          alert(data.message);
        }
      }
    }

    setDownloading(false);
  };

  useEffect(() => {
    if (codigoEntrega) {
      download();
    }
  }, [codigoEntrega]);

  useEffect(() => {
    setCodigoEntrega(params.codigoEntrega);
    setIsLoading(false);
  }, []);

  if (isLoading) {
    return <Loading />;
  }

  return (
    <Container fluid style={{ padding: '5em' }}>
      <h5 className="mb-3">Remitos de la entrega {codigoEntrega}</h5>

      <button
        type="button"
        className="btn btn-sm btn-outline-secondary"
        disabled={downloading}
        onClick={(event) => {
          event.preventDefault();
          download();
        }}
      >
        {downloading ? 'Descargando...' : 'Descargar'}
      </button>
    </Container>
  );
};

export default DownloadEntrega;
