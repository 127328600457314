import { useEffect, useState } from 'react';
import ReactDatePicker, { registerLocale, setDefaultLocale } from  'react-datepicker';
import dayjs from 'dayjs';
import useAxios from 'hooks/useAxios';
import 'react-datepicker/dist/react-datepicker.css';
import es from 'date-fns/locale/es';

registerLocale('es', es)
setDefaultLocale('es');

export default function FechaEstDespachoCellRenderer({ data, setValue }) {
  const axios = useAxios();
  const [isUpdating, setIsUpdating] = useState(false);
  const [inputValue, setInputValue] = useState(null);

  useEffect(() => {
    const value = data.entrega_despacho_estimado_el;
    setInputValue(value ? dayjs(value) : null);
  }, [data]);

  const updateEntrega = async (date) => {
    const prev = data.entrega_despacho_estimado_el ? dayjs(data.entrega_despacho_estimado_el) : null;

    if (prev?.format('YYYY-MM-DD') === date.format('YYYY-MM-DD')) {
      return;
    }

    const today = dayjs();

    if (date.isBefore(today, 'day')) {
      alert(`La fecha debe ser igual o posterior al ${today.format('DD/MM/YYYY')}`);
      return;
    }

    setIsUpdating(true);

    try {
      await axios.put(`/logistica/entregas/${data.entrega_id}`, {
        despacho_estimado_el: date.format('YYYY-MM-DD'),
      });

      setValue(date);
    } catch (error) {
      console.error(error);
      alert('No se pudo actualizar la fecha')
    }

    setIsUpdating(false);
  };

  if (data.entrega_contabilizado_el) {
    return (
      <div style={{ width: '100%' }}>
        {inputValue?.format('DD/MM/YYYY')}
      </div>
    );
  }

  return (
    <ReactDatePicker
      portalId="root"
      dateFormat="dd/MM/yyyy"
      className="form-control form-control-sm text-center no-glow"
      minDate={new Date}
      selected={inputValue?.toDate()}
      onChange={(date) => date && setInputValue(dayjs(date))}
      onBlur={() => updateEntrega(inputValue)}
      onSelect={(date) => updateEntrega(dayjs(date))}
      disabled={isUpdating}
    />
  );
};
