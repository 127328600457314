import mapboxgl from 'mapbox-gl';
import polyline from '@mapbox/polyline';
import dayjs from 'dayjs';
import config from '../config';

const statuses = {
  pendiente: {
    title: 'Pendiente',
    color: '#f9ba00',
  },
  encurso: {
    title: 'En Curso',
    color: '#0DB030',
  },
};

const markerBanderas = (transporte, onClick) => {
  const coordenadas = {
    start: transporte.ruta.legs[0].start_location,
    end: transporte.ruta.legs[transporte.ruta.legs.length - 1].end_location,
  };

  return Object.keys(coordenadas).map((key) => {
    const transporteLink = document.createElement('a');
    transporteLink.href = '#';
    transporteLink.innerHTML = 'Ir al viaje';
    transporteLink.addEventListener('click', (event) => {
      event.preventDefault();
      onClick && onClick(transporte);
    });

    const popupDiv = document.createElement('div');
    popupDiv.style.cssText =
      'display: flex; flex-direction: column; align-items: center; justify-content: center; font-size: 1rem; gap: 5px;';
    popupDiv.innerHTML = `
      <div style="font-weight: bold;">${
        key === 'start' ? 'Inicio' : 'Final'
      }</div>
      <div>Transporte ${transporte.codigo}</div>
    `;
    popupDiv.append(transporteLink);

    const popup = new mapboxgl.Popup({ offset: 25 }).setDOMContent(popupDiv);

    const markerDiv = document.createElement('div');

    markerDiv.style.cursor = 'pointer';
    markerDiv.className = key === 'start' ? 'bandera--inicio' : 'bandera--final';

    return new mapboxgl.Marker(markerDiv)
      .setLngLat(coordenadas[key])
      .setPopup(popup);
  });
};

const markerDestino = (entrega) => {
  const popup = new mapboxgl.Popup({
    offset: 25,
    className: 'mapboxgl-popup-camion',
    closeButton: false,
    closeOnClick: false,
  }).setText(`${entrega.destinatario.codigo} ${entrega.destinatario.nombre}`);

  const markerDiv = document.createElement('div');
  markerDiv.className = `marker-destino ${
    entrega.entregado_el
      ? 'bandera--entregado'
      : 'bandera--pendiente'
  }`;

  const marker = new mapboxgl.Marker(markerDiv)
    .setLngLat(entrega.geolocalizacion)
    .setPopup(popup);

  markerDiv.addEventListener('mouseenter', () => {
    marker.togglePopup();
  });

  markerDiv.addEventListener('mouseleave', () => {
    marker.togglePopup();
  });

  return marker;
};

const markerCamion = (transporte, onClick, mapRef) => {
  const popup = new mapboxgl.Popup({
    offset: 25,
    className: 'mapboxgl-popup-camion',
    closeButton: false,
    closeOnClick: false,
  }).setText(`Transporte Nro. ${transporte.codigo}`);

  const horasDesdeUltimaPosicion = dayjs().diff(
    dayjs(transporte.ultima_geolocalizacion_el),
    'h',
  );

  const markerDiv = document.createElement('div');

  if (horasDesdeUltimaPosicion >= config.horasOffline) {
    markerDiv.className = 'camion--offline';
  } else {
    markerDiv.className = `camion--${transporte.marca.toLowerCase()}`;
  }

  const marker = new mapboxgl.Marker(markerDiv)
    .setLngLat(ubicacionTransporte(transporte))
    .setRotation(transporte.rotacion ?? 0)
    .setPopup(popup);

  markerDiv.addEventListener('click', () => onClick && onClick(transporte));

  markerDiv.addEventListener('mouseenter', () => {
    mapRef.current.setLayoutProperty(
      `ruta-${transporte.id}`,
      'visibility',
      'visible',
    );
    mapRef.current.setLayoutProperty(`rutas`, 'visibility', 'none');
    marker.togglePopup();
  });

  markerDiv.addEventListener('mouseleave', () => {
    mapRef.current.setLayoutProperty(
      `ruta-${transporte.id}`,
      'visibility',
      'none',
    );
    mapRef.current.setLayoutProperty(`rutas`, 'visibility', 'visible');
    marker.togglePopup();
  });

  mapRef.current.on('zoom', () => {
    const zoom = mapRef.current.getZoom();
    markerDiv.style.visibility = zoom <= 6 ? 'hidden' : 'visible';
  });

  return marker;
};

const ubicacionTransporte = (transporte) => {
  if (transporte.ultima_geolocalizacion) {
    return transporte.ultima_geolocalizacion;
  }

  if (transporte.ruta && transporte.ruta.legs.length > 0) {
    return transporte.ruta.legs[0].start_location;
  }

  if (
    transporte.entregas &&
    transporte.entregas.length > 0 &&
    transporte.entregas[0].geolocalizacion
  ) {
    return transporte.entregas[0].geolocalizacion;
  }

  return config.geolocalizacionDespachoVenadoTuerto;
};

const dibujarTransportes = (map, transportes, onTransporteClick) => {
  map.clean();

  // Todas las rutas

  map.addSource('rutas', {
    type: 'geojson',
    data: {
      type: 'FeatureCollection',
      features: transportes
        .filter((transporte) => transporte.ruta?.overview_polyline)
        .map((transporte) => ({
          type: 'Feature',
          geometry: polyline.toGeoJSON(
            transporte.ruta.overview_polyline.points,
          ),
        })),
    },
  });

  map.addLayer('rutas', 'rutas', {
    type: 'line',
    paint: {
      'line-color': '#4a88bd',
      'line-width': 3,
      'line-opacity': 0.5,
    },
  });

  // Por transporte

  transportes.forEach((transporte) => {
    // Ruta (efecto hover)

    map.addSource(`ruta-${transporte.id}`, {
      type: 'geojson',
      data: {
        type: 'Feature',
        geometry: transporte.ruta
          ? polyline.toGeoJSON(transporte.ruta.overview_polyline.points)
          : null,
      },
    });

    map.addLayer(`ruta-${transporte.id}`, `ruta-${transporte.id}`, {
      type: 'line',
      layout: {
        visibility: 'none',
      },
      paint: {
        'line-color': 'red',
        'line-width': 5,
        'line-opacity': 0.8,
      },
    });

    // Destinos

    transporte.entregas.map((entrega) => {
      if (entrega.geolocalizacion) {
        map.addMarker(markerDestino(entrega));
      }
    })

    // Banderas

    if (transporte.ruta?.legs?.length) {
      markerBanderas(transporte, onTransporteClick).forEach((bandera) => {
        return map.addMarker(bandera);
      });
    }

    // Camión

    map.addMarker(markerCamion(transporte, onTransporteClick, map.mapRef));

    // Tracking

    if (transporte.tracking) {
      map.addSource(`tracking-${transporte.id}`, {
        type: 'geojson',
        data: {
          type: 'Feature',
          geometry: {
            type: 'LineString',
            coordinates: transporte.tracking,
          },
        },
      });

      map.addLayer(`tracking-${transporte.id}`, `tracking-${transporte.id}`, {
        type: 'line',
        layout: {
          visibility: 'none',
        },
        paint: {
          'line-color': '#00FFFF',
          'line-width': 2,
          'line-opacity': 0.5,
        },
      });
    }
  });
};

const formatNumber = (value, decimals = 0) =>
  parseFloat(value ?? 0).toLocaleString('es-AR', { minimumFractionDigits: decimals, maximumFractionDigits: decimals });

export default {
  statuses,
  dibujarTransportes,
  ubicacionTransporte,
  formatNumber,
};
