import { useState } from 'react';
import useAxios from 'hooks/useAxios';

const RemitosCellRenderer = (props) => {
  const axios = useAxios();
  const [downloading, setDownloading] = useState(false);

  if (parseInt(props.value)) {
    return (
      <button
        type="button"
        className="btn btn-sm btn-outline-secondary"
        disabled={downloading}
        onClick={async (event) => {
          setDownloading(true);

          event.preventDefault();

          try {
            const response = await axios.get(
              `/logistica/entregas/download?codigo=${props.data.entrega_codigo}`,
              { responseType: 'blob' },
            );

            const href = window.URL.createObjectURL(response.data);
            const anchor = document.createElement('a');

            anchor.href = href;
            anchor.setAttribute(
              'download',
              response.headers['x-suggested-filename'] ?? 'Remitos',
            );
            anchor.click();

            window.URL.revokeObjectURL(href);
          } catch (e) {
            if (e.response?.data) {
              const text = await e.response.data.text();
              const data = JSON.parse(text);

              if (data.message) {
                alert(data.message);
              }
            }
          }

          setDownloading(false);
        }}
      >
        {downloading ? 'Descargando...' : 'Descargar'}
      </button>
    );
  }

  return null;
};

export default RemitosCellRenderer;
