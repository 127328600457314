import React, { useEffect, useState } from 'react';
import { ListGroup } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import styled from 'styled-components';
import Chat from 'components/Chat';
import Page from 'components/Page';
import Loading from 'components/Loading';
import useAxios from 'hooks/useAxios';

const ChatWrapper = styled.div`
  display: flex;
  flex-grow: 1;
`;

const ChatData = styled.div`
  height: 100%;
  max-width: 360px;
  margin-right: 20px;
  background: #fff;
  overflow-y: auto;
`;

const ChatDataLink = styled.span`
  color: #209bdb;
  cursor: pointer;
  fontSize: 16px;
`;

const estadosEntrega = {
  encurso: 'En Curso',
  pendiente: 'Pendiente',
};

const MensajesDetail = () => {
  const axios = useAxios();
  const navigate = useNavigate();
  const { entregaId } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [mostrarMateriales, setMostrarMateriales] = useState(false);
  const [data, setData] = useState();
  const [mensajeEnviado, setMensajeEnviado] = useState(false);

  const fetchData = async () => {
    try {
      const response = await axios.get(`/logistica/entregas/${entregaId}/mensajes`);
      setData(response.data);
    } catch (error) {
      console.error(error);
    }

    setIsLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    setMensajeEnviado(false);
    fetchData();
  }, [mensajeEnviado]);

  const textInBadge = (text, colorName) => (
    <span
      className={` px-2 py-1 text-xs font-bold leading-none text-${colorName}-100 bg-${colorName}-700 rounded`}
    >
      {text}
    </span>
  );

  return (
    <Page>
      {isLoading && <Loading />}

      <Page.Content>
        <Page.Header title="Mensajes" />

        <ChatWrapper>
          {data && (
            <>
              <ChatData>
                <ListGroup variant="flush">
                  <ListGroup.Item>
                    <strong>Distribuidor: </strong>
                    {data.distribuidor.nombre}
                  </ListGroup.Item>
                  <ListGroup.Item>
                    <strong>Entrega: </strong>
                    {textInBadge(
                      estadosEntrega[data.estado],
                      `${data.estado === 'encurso' ? 'green' : 'gray'}`,
                    )}
                  </ListGroup.Item>
                  <ListGroup.Item>
                    <strong>Fecha estimada de entrega: </strong>
                    {dayjs(data.entrega_estimada_el).format(
                      'MMMM D, YYYY h:mm A',
                    )}
                  </ListGroup.Item>
                  <ListGroup.Item>
                    <strong>N° de pedido: </strong>
                    {data?.numero_pedido}
                  </ListGroup.Item>
                  <ListGroup.Item>
                    <strong>N° de entrega: </strong>
                    <ChatDataLink
                      onClick={() => navigate(`/entregas?codigo=${data.codigo}`)}
                    >
                      {data.codigo}
                    </ChatDataLink>
                  </ListGroup.Item>
                  <ListGroup.Item>
                    <strong>Localidad: </strong>
                    {`${data.destinatario.localidad.nombre}, ${data.destinatario.localidad.provincia}`}
                  </ListGroup.Item>
                  <ListGroup.Item>
                    <strong>Destinatario: </strong>
                    {data.destinatario.nombre}
                  </ListGroup.Item>
                  <ListGroup.Item>
                    <strong>Materiales: </strong>
                    <ChatDataLink
                      onClick={() => setMostrarMateriales(!mostrarMateriales)}
                    >
                      {!mostrarMateriales && data.detalle.length > 0 && 'Ver'}
                    </ChatDataLink>
                    {mostrarMateriales &&
                      data.detalle.map((material) => (
                        <p key={material.codigo}>
                          {material.material_descripcion} x {material.cantidad}
                          <br />
                        </p>
                      ))}
                  </ListGroup.Item>
                </ListGroup>
              </ChatData>
              <Chat
                mensajes={data.mensajes}
                motivo={data.mensajes[0].tipo}
                setMensajeEnviado={setMensajeEnviado}
              />
            </>
          )}
        </ChatWrapper>
      </Page.Content>
    </Page>
  );
};

export default MensajesDetail;
