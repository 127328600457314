import { useEffect, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import Page from 'components/Page';
import DashboardAccordion from 'components/DashboardAccordion';
import FilterGroup from 'components/FilterGroup';
import useQuery from 'hooks/useQuery';
import useAxios from 'hooks/useAxios';
import ValueWidget from 'components/ValueWidget';
import TimelineWidget from 'components/TimelineWidget';
import ChartWidget from 'components/ChartWidget';
import DashboardCard from 'components/DashboardCard';
import Loading from 'components/Loading';

const defaultWidgetFilters = {
  marcas: {
    title: 'Marca',
    options: [],
  },
  tipos: {
    title: 'Tipos de pedido',
    options: [],
  },
  regiones: {
    title: 'Región',
    options: [],
  },
  // comerciales: {
  //   title: 'Comercial',
  //   options: [],
  // },
};

const defaultChartFilters = {
  marcas: {
    title: 'Marca',
    options: [],
  },
  cultivos: {
    title: 'Cultivo',
    options: [],
  },
  tipos: {
    title: 'Tipos de pedido',
    options: [],
  },
  regiones: {
    title: 'Región',
    options: [],
  },
  meses: {
    title: 'Meses',
    options: [
      { key: '1', value: 'Enero' },
      { key: '2', value: 'Febrero' },
      { key: '3', value: 'Marzo' },
      { key: '4', value: 'Abril' },
      { key: '5', value: 'Mayo' },
      { key: '6', value: 'Junio' },
      { key: '7', value: 'Julio' },
      { key: '8', value: 'Agosto' },
      { key: '9', value: 'Septiembre' },
      { key: '10', value: 'Octubre' },
      { key: '11', value: 'Noviembre' },
      { key: '12', value: 'Diciembre' },
    ],
  },
};

const LoadingCard = ({ style }) => (
  <DashboardCard className="col">
    <DashboardCard.Title>
      Cargando...
    </DashboardCard.Title>
    <div className="d-flex justify-content-center align-items-center" style={style}>
      <Spinner animation="border" role="status" style={{ color: '#ddd' }}>
        <span className="visually-hidden">Loading...</span>
      </Spinner>
    </div>
  </DashboardCard>
);

const Widgets = ({ filters }) => {
  const layout = [
    {
      title: 'Principal',
      rows: [
        ['viajes_despachados_ytd', 'viajes_despachados_mes', 'lead_time_ytd'],
        ['ot_mes', 'bolsas_despachadas_mes', 'ratio_consolidacion_mes'],
        ['ot_ytd', 'bolsas_despachadas_ytd', 'ratio_consolidacion_ytd'],
        ['ratio_despacho_mes', 'ratio_despacho_ytd'],
        ['grafico_despachos_mensuales'],
      ],
    },
    {
      title: 'Viajes',
      rows: [
        ['viajes_planificados', 'viajes_despachados_mes', 'viajes_despachados_ytd'],
        ['destinos_por_viaje_mes', 'destinos_por_viaje_ytd'],
        ['km_recorridos_mes', 'km_recorridos_ytd'],
        ['demora_media_gps', 'demora_media_etapas'],
        ['km_promedio', 'km_bolsas_mes', 'km_bolsas_ytd'],
        ['consolidacion_tr1_mes', 'consolidacion_tr1_ytd', 'consolidacion_tr2_mes', 'consolidacion_tr2_ytd'],
      ],
    },
    {
      title: 'Entregas',
      rows: [
        ['bolsas_planificadas', 'bolsas_despachadas_mes', 'bolsas_despachadas_ytd'],
        ['ot_mes', 'ot_ytd', 'drp_adherance_mes'],
        ['entregas_on_time', 'entregas_con_demora', 'entregas_en_curso_con_demora'],
      ],
    },
    {
      title: 'Pedidos',
      rows: [
        ['pedido_promedio_mes', 'pedido_promedio_ytd'],
        // ['visitas_promedio_destinatario_mes', 'visitas_promedio_destinatario_ytd', 'visitas_promedio_destinatario_30'],
      ],
    },
    {
      title: 'Líneas temporales',
      rows: [
        ['viajes_despachados_timeline'],
        ['bolsas_despachadas_timeline'],
        ['km_recorridos_timeline'],
        ['ot_timeline'],
        ['lead_time_timeline'],
        ['drp_adherance_timeline'],
        ['hse_timeline'],
        ['consolidacion_tr1_timeline'],
        ['consolidacion_tr2_timeline'],
      ],
    },
  ];
  const axios = useAxios();
  const query = useQuery();
  const [selectedOptions, setSelectedOptions] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [widgets, setWidgets] = useState(null);

  const fetchData = async () => {
    setIsLoading(true);

    try {
      const response = await axios.get('/logistica/dashboard-kpis', {
        params: {
          month: query.get('mes'),
          ...selectedOptions
        }
      });

      setWidgets(response.data);
    } catch (error) {
      console.error(error);
    }

    setIsLoading(false);
  };

  const handleCleanFilters = () => {
    setSelectedOptions({});
  };

  const handleApplyFilter = (key, selected) => {
    setSelectedOptions({
      ...selectedOptions,
      [key]: selected,
    });
  };

  useEffect(() => {
    fetchData();
  }, [selectedOptions]);

  return (
    <>
      <Page.Header title="Dashboard">
        <FilterGroup
          filters={filters}
          selectedOptions={selectedOptions}
          onClean={handleCleanFilters}
          onApply={handleApplyFilter}
        />
      </Page.Header>

      {layout.map((section, index) => (
        <DashboardAccordion key={index} title={section.title}>
          {section.rows.map((row, index) => (
            <div key={index} className="d-flex gap-3">
              {row.map((widgetKey) => {
                if (isLoading) {
                  return <LoadingCard key={widgetKey} style={{ height: '100px' }} />;
                }

                const widget = widgets[widgetKey];

                if (widget.type === 'chart') {
                  return <ChartWidget
                    key={widgetKey}
                    title={widget.title}
                    type={widget.chartType}
                    series={widget.series}
                    categories={widget.categories}
                  />;
                }

                if (widget.type === 'timeline') {
                  return <TimelineWidget
                    key={widgetKey}
                    widget={widget}
                    ytd={widgets[widgetKey.replace('_timeline', '_ytd')]}
                  />;
                }

                return <ValueWidget key={widgetKey} widget={widget} />;
              })}
            </div>
          ))}
        </DashboardAccordion>
      ))}
    </>
  );
};

const Charts = ({ filters }) => {
  const layout = [
    ['despachos_zona', 'despachos_mensuales'],
    ['despachos_semanales'],
    ['bolsas_entregas_viajes'],
  ];
  const axios = useAxios();
  const query = useQuery();
  const [isLoading, setIsLoading] = useState(true);
  const [selectedOptions, setSelectedOptions] = useState({});
  const [charts, setCharts] = useState(null);

  const fetchData = async () => {
    setIsLoading(true);

    try {
      const response = await axios.get('/logistica/dashboard-charts', {
        params: {
          month: query.get('mes'),
          ...selectedOptions
        }
      });

      setCharts(response.data);
    } catch (error) {
      console.error(error);
    }

    setIsLoading(false);
  };

  const handleCleanFilters = () => {
    setSelectedOptions({});
  };

  const handleApplyFilter = (key, selected) => {
    setSelectedOptions({
      ...selectedOptions,
      [key]: selected,
    });
  };

  useEffect(() => {
    fetchData();
  }, [selectedOptions]);

  return (
    <DashboardAccordion title="Gráficos">
      <FilterGroup
        filters={filters}
        selectedOptions={selectedOptions}
        onClean={handleCleanFilters}
        onApply={handleApplyFilter}
      />

      {layout.map((row, index) => (
        <div key={index} className="d-flex gap-3">
          {row.map((chartKey) => {
            if (isLoading) {
              return <LoadingCard key={chartKey} style={{ height: '350px' }} />
            }

            const chart = charts[chartKey];

            return <ChartWidget
              key={chartKey}
              title={chart.title}
              icon={selectedOptions.cultivos?.length === 1 ? selectedOptions.cultivos[0] : null}
              theme={selectedOptions.marcas?.length === 1 ? selectedOptions.marcas[0] : null}
              type={chart.type}
              series={chart.series}
              categories={chart.categories}
              mixed={chartKey === 'bolsas_entregas_viajes'}
            />;
          })}
        </div>
      ))}
    </DashboardAccordion>
  );
};

export default function Dashboard() {
  const axios = useAxios();
  const [widgetFilters, setWidgetFilters] = useState({});
  const [chartFilters, setChartFilters] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  const fetchFilters = async () => {
    setIsLoading(true);

    try {
      const response = await axios.get('/logistica/dashboard-filters');

      Object.keys(response.data ?? {}).forEach((key) => {
        if (defaultWidgetFilters[key]) {
          defaultWidgetFilters[key].options = response.data[key];
        }

        if (defaultChartFilters[key]) {
          defaultChartFilters[key].options = response.data[key];
        }
      });

      setWidgetFilters({...defaultWidgetFilters});
      setChartFilters({...defaultChartFilters});
    } catch (error) {
      console.error(error);
    }

    setIsLoading(false);
  };

  useEffect(() => {
    fetchFilters();
  }, []);

  return (
    <Page>
      {isLoading ? <Loading /> : (
        <Page.Content>
          <Widgets filters={widgetFilters} />
          <Charts filters={chartFilters} />
        </Page.Content>
      )}
    </Page>
  );
}
