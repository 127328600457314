import { Spinner } from 'react-bootstrap';

export default function Loading() {
  return (
    <div
      className="d-flex flex-column justify-content-center align-items-center text-white"
      style={{
        position: 'absolute',
        top: 0,
        right: 0,
        left: 0,
        bottom: 0,
        zIndex: 10,
        background: 'rgb(50 50 50 / 50%)'
      }}
    >
      <Spinner animation="grow"></Spinner>
      <p>Cargando...</p>
    </div>
  )
};
