import { Link } from 'react-router-dom';
import { Container, Dropdown, Navbar } from 'react-bootstrap';
import { useAuth0 } from '@auth0/auth0-react';
import usuarioIcon from 'assets/usuario.svg';
import mensajesIcon from 'assets/mensajes.svg';
import enviosIcon from 'assets/envios.svg';
import logoIcon from 'assets/logo.svg';
import mapaIcon from 'assets/mapa.svg';
import dashboardIcon from 'assets/dashboard.svg';
import config from '../config';

const NavLink = ({ to, label, icon }) => (
  <Link to={to} className="d-flex align-items-center text-white mx-3 text-decoration-none">
    <img src={icon} alt={label} className="me-2" />
    {label}
  </Link>
);

export default function NavBar() {
  const { user, logout } = useAuth0();
  const rol = user[`${config.auth0.namespace}rol`];

  return (
    <Navbar variant="dark" style={{ backgroundColor: 'darkblue' }}>
      <Container fluid>
        <Navbar.Brand href="/">
          <img src={logoIcon} alt="EnCamino" />
        </Navbar.Brand>
        <div className="d-flex me-auto">
          <NavLink to="/" label="Mapa" icon={mapaIcon} />
          <NavLink to="/entregas" label="Entregas" icon={enviosIcon} />
          {rol === 'logistica_nk' && (<NavLink to="/mensajes" label="Mensajes" icon={mensajesIcon} />)}
          {rol === 'logistica_admin' && (<NavLink to="/transportistas" label="Transportistas" icon={usuarioIcon} />)}
          <NavLink to="/destinatarios" label="Destinatarios" icon={usuarioIcon} />
          <NavLink to="/dashboard" label="Dashboard" icon={dashboardIcon} />
        </div>
        <Dropdown align="end" className="text-white">
          <Dropdown.Toggle style={{ backgroundColor: 'darkblue', border: 'none' }}>
            <img src={user.picture} alt={user.name} width="20" height="20" />
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item>Versión 2.0</Dropdown.Item>
            <Dropdown.Item
              onClick={() => {
                window.localStorage.removeItem('token');

                logout({
                  returnTo: window.location.origin,
                });
              }}
            >
              Logout
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </Container>
    </Navbar>
  );
};
