import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import isToday from 'dayjs/plugin/isToday';
import Page from 'components/Page';
import Loading from 'components/Loading';
import TablePagination from 'components/TablePagination';
import useAxios from 'hooks/useAxios';

dayjs.extend(relativeTime);
dayjs.extend(isToday);

const columns = [
  {
    headerName: '#',
    field: 'id',
    type: 'numericColumn',
    width: 100,
  },
  {
    headerName: 'Distribuidor',
    field: 'entrega.distribuidor.nombre',
    width: 200,
  },
  {
    headerName: 'Fecha',
    field: 'creado_el',
    type: 'numericColumn',
    width: 150,
    valueFormatter: ({ value }) => dayjs(value).isToday()
      ? dayjs(value).format('HH:mm')
      : dayjs().to(dayjs(value)),
  },
  {
    headerName: 'Entrega',
    width: 150,
    field: 'entrega.codigo',
  },
  {
    headerName: 'Nro. Pedido',
    width: 150,
    field: 'entrega.numero_pedido',
  },
  {
    field: 'texto',
    headerName: 'Mensaje',
  },
];

export default function mensajes() {
  const axios = useAxios();
  const [isLoading, setIsLoading] = useState(false);
  const [mensajes, setMensajes] = useState([]);
  const navigate = useNavigate();

  const fetchData = async () => {
    setIsLoading(true);

    try {
      const response = await axios.get('/logistica/entregas/mensajes');
      setMensajes(response.data);
    } catch (error) {
      console.error(error);
    }

    setIsLoading(false);
  };

  const handleEdit = (mensaje) => {
    navigate(`/mensajes/${mensaje.entrega_id}`);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Page>
      {isLoading && <Loading />}

      <Page.Content>
          <Page.Header title="Mensajes" />

          <div className="flex-grow-1">
            <TablePagination
              columns={columns}
              data={mensajes}
              onEdit={handleEdit}
              pagination
            />
          </div>
      </Page.Content>
    </Page>
  );
};
