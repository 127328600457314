import { useState, useRef, useEffect } from 'react';
import { Form, Button } from 'react-bootstrap';
import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import agGridLocale from 'utils/agGrigLocale';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';

const styles = {
  cellDefaultStyle: { display: 'flex', justifyContent: 'center' },
};

export default function TablePagination({
  columns = [],
  frameworkComponents = {},
  HeaderComponent = null,
  BeforeComponent = null,
  data = [],
  onDelete,
  onEdit,
  onResetPin,
  onImport,
  pagination = false,
  paginationPageSize = 10,
  onCellValueChanged,
}) {
  const [search, setSearch] = useState('');
  const [isImporting, setIsImporting] = useState(false);
  const gridApi = useRef(null);
  const importRef = useRef();

  const CellButtonEdit = (props) => (
    <button
      type="button"
      className="btn btn-sm btn-outline-primary"
      onClick={() => onEdit(props.data)}
    >
      Modificar
    </button>
  );
  const CellButtonDelete = (props) => (
    <button
      type="button"
      className="btn btn-sm btn-outline-danger"
      onClick={() => onDelete(props.data)}
    >
      Eliminar
    </button>
  );
  const CellButtonResetPin = (props) => (
    <button
      type="button"
      className="btn btn-sm btn-outline-danger"
      onClick={() => onResetPin(props.data)}
    >
      Resetear PIN
    </button>
  );

  const buttonsColumns = [
    onEdit && {
      headerName: '',
      center: true,
      cellRenderer: 'CellButtonEdit',
      width: 150,
    },
    onResetPin && {
      headerName: '',
      center: true,
      cellRenderer: 'CellButtonResetPin',
      width: 150,
    },
    onDelete && {
      headerName: '',
      center: true,
      cellRenderer: 'CellButtonDelete',
      width: 150,
    },
  ].filter((x) => x);

  const onGridReady = (params) => {
    gridApi.current = params.api;
  };

  const onChangeSearch = (e) => {
    const value = e.target.value;
    setSearch(value);
  };

  const onButtonDownload = () => {
    if (gridApi.current) {
      gridApi.current.exportDataAsCsv();
    }
  };

  return (
    <div className="d-flex flex-column h-100">
      <div className="d-flex justify-content-between align-items-center mb-2">
        <div className="d-flex">
          <Form.Control
            type="search"
            placeholder="Buscar"
            onChange={onChangeSearch}
            className="me-3"
          />
          <Button size="sm" className="text-nowrap" onClick={onButtonDownload}>
            Descargar CSV
          </Button>

          {onImport && (
            <>
              <input
                type="file"
                ref={importRef}
                accept=".xlsx,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                onChange={async (event) => {
                  const target = event.target;
                  const file = target.files[0];

                  if (file) {
                    setIsImporting(true);

                    await onImport(file);

                    target.value = '';
                    setIsImporting(false);
                  }
                }}
                style={{ display: 'none' }}
              />
              <Button
                size="sm"
                className="text-nowrap ms-2"
                onClick={() => importRef.current.click()}
                disabled={isImporting}
              >
                {isImporting ? 'Importando...' : 'Importar datos'}
              </Button>
            </>
          )}
        </div>
        {HeaderComponent && <HeaderComponent />}
      </div>
      {BeforeComponent && <div className="mb-2"><BeforeComponent /></div>}
      <div className="ag-theme-alpine bg-danger flex-grow-1">
        <AgGridReact
          frameworkComponents={{
            ...frameworkComponents,
            CellButtonEdit,
            CellButtonDelete,
            CellButtonResetPin,
          }}
          rowData={data}
          onGridReady={onGridReady}
          quickFilterText={search}
          enableCellTextSelection={true}
          defaultColDef={{ resizable: true, sortable: true }}
          localeText={agGridLocale.es}
          pagination={pagination}
          suppressCellSelection={true}
          paginationPageSize={paginationPageSize}
          onCellValueChanged={onCellValueChanged}
        >
          {[...columns, ...buttonsColumns].map((item, index) => (
            <AgGridColumn
              key={index}
              field={item.field}
              type={item.type}
              headerName={item.headerName}
              headerClass={item.headerClass || 'ag-center-aligned-header'}
              cellRenderer={item.cellRenderer || ''}
              cellStyle={item.center ? styles.cellDefaultStyle : {}}
              onCellValueChanged={item.onCellValueChanged}
              valueFormatter={item.valueFormatter}
              width={item.width}
            />
          ))}
        </AgGridReact>
      </div>
    </div>
  );
}
