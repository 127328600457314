import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import FilterOptions from './FilterOptions';
import { ReactComponent as FilterIcon } from 'assets/filter.svg';

export default function Filter({
  defaultTitle,
  defaultSelectedOptions,
  options,
  onApply,
  className,
  style,
  defaultButtonClassName = 'btn-outline-secondary'
}) {
  const [show, setShow] = useState(false);
  const [title, setTitle] = useState('');
  const [selectedOptions, setSelectedOptions] = useState([]);

  const handleApply = () => {
    setShow(false);
    onApply && onApply([...selectedOptions]);
  };

  const handleCancel = () => {
    setShow(false);
    setSelectedOptions([...defaultSelectedOptions]);
  };

  useEffect(() => {
    if (defaultSelectedOptions.length === 1) {
      const firstSelectedOption = options.find(
        (option) => option.key === defaultSelectedOptions[0],
      );

      if (firstSelectedOption) {
        setTitle(firstSelectedOption.value);
      } else {
        setTitle(defaultSelectedOptions[0]);
      }
    } else if (defaultSelectedOptions.length > 1) {
      setTitle(`${defaultSelectedOptions.length} ${defaultTitle}`);
    } else {
      setTitle(defaultTitle);
    }

    setSelectedOptions([...defaultSelectedOptions]);
  }, [defaultSelectedOptions]);

  return (
    <>
      <button
        type="button"
        className={`btn btn-sm ${defaultSelectedOptions.length > 0 ? 'btn-primary' : defaultButtonClassName} ${className}`}
        style={style}
        onClick={() => setShow(!show)}
      >
        <FilterIcon /> {title}
      </button>
      <Modal show={show} onHide={handleCancel} className="modal">
        <Modal.Header onClose={handleCancel}>{title}</Modal.Header>
        <Modal.Body>
          <FilterOptions
            options={options}
            placeholder={`Buscar ${String(title ?? '').toLowerCase()}`}
            selectedOptions={selectedOptions}
            setSelectedOptions={setSelectedOptions}
          />
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-primary"
            type="button"
            onClick={handleApply}
          >
            Aplicar
          </button>
          <button
            className="btn btn-outline-secondary"
            type="button"
            onClick={handleCancel}
          >
            Cancelar
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
