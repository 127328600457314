import { useState, useEffect, useRef } from 'react';
import mapboxgl from 'mapbox-gl';
import config from '../config';

export default function useMap() {
  const [isLoaded, setIsLoaded] = useState(false);
  const prevMarkers = useRef([]);
  const prevLayers = useRef([]);
  const prevSources = useRef([]);
  const map = useRef();
  const container = useRef();

  const clean = () => {
    prevMarkers.current.forEach((marker) => marker.remove());
    prevLayers.current.forEach((layer) => map.current.removeLayer(layer));
    prevSources.current.forEach((source) => map.current.removeSource(source));

    prevMarkers.current = [];
    prevLayers.current = [];
    prevSources.current = [];
  }

  const addSource = (id, props) => {
    if (map.current.getSource(id)) {
      map.current.getSource(id).setData(props.data);
    } else {
      map.current.addSource(id, props);
      prevSources.current.push(id);
    }
  };

  const addLayer = (id, source, props) => {
    if (!map.current.getLayer(id)) {
      map.current.addLayer({
          id,
          source,
          ...props,
      });
      prevLayers.current.push(id);
    }
  };

  const showLayer = (id, value) => {
    map.current.setLayoutProperty(id, 'visibility', value ? 'visible' : 'none');
  };

  const flyTo = (center, zoom = 6) => {
    map.current.flyTo({
      center,
      zoom,
    });
  };

  const addMarker = (marker) => {
    prevMarkers.current.push(marker);
    marker.addTo(map.current)
  };

  useEffect(() => {
    if (!map.current && container.current) {
      map.current = new mapboxgl.Map({
        accessToken: config.tokenMapbox,
        style: config.mapBoxStyle,
        center: config.geolocalizacionDespachoVenadoTuerto,
        container: container.current,
        zoom: 6,
      });

      map.current.on('load', () => {
        setIsLoaded(true);
      })
    }
  }, [container]);

  useEffect(() => {
    if (isLoaded) {
      map.current.addControl(new mapboxgl.NavigationControl(), 'bottom-right');
    }
  }, [isLoaded]);

  // useEffect(() => {
  //   return () => {
  //     map.current.remove();
  //     map.current = null;
  //   }
  // }, []);

  return {
    mapRef: map,
    containerRef: container,
    isLoaded,
    addSource,
    addLayer,
    addMarker,
    clean,
    showLayer,
    flyTo,
  };
};
