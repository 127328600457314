import { useEffect, useState } from 'react';

export default function Toggle({ value, onChange = () => {} }) {
  const [toggle, setToggle] = useState();

  const triggerToggle = () => {
    setToggle(!toggle);
    onChange(!toggle);
  };

  useEffect(() => {
    setToggle(value)
  }, [value]);

  return (
    <div
      className={`toggle ${toggle ? 'toggle--checked' : ''}`}
      onClick={triggerToggle}
    >
      <div className="toggle-container">
        <div className="toggle-check" />
        <div className="toggle-uncheck" />
      </div>
      <div className="toggle-circle"></div>
      <input
        className="toggle-input"
        type="checkbox"
        aria-label="Toggle Button"
      />
    </div>
  );
};
