import { useState } from 'react';
import { Modal, Row, Col, Form, Button } from 'react-bootstrap';

export default function CrearDemora({ motivos, onCreate }) {
  const [validated, setValidated] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [form, setForm] = useState({
    horas: null,
    motivoId: null,
  });

  const handleClose = () => {
    setForm({
      horas: null,
      motivoId: null,
    });
    setShowModal(false);
    setValidated(false);
  };

  const handleSubmit = (event) => {
    const target = event.currentTarget;

    event.preventDefault();
    event.stopPropagation();

    if (target.checkValidity()) {
      onCreate(form);
      handleClose();
    } else {
      setValidated(true);
    }
  };

  return (
    <>
      <button
        type="button"
        className="btn btn-warning fw-bold"
        onClick={() => { setShowModal(true) }}
      >
        Cargar demora
      </button>
      <Modal
        onHide={handleClose}
        show={showModal}
        backdrop
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title className="m-title">Cargar demora</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form
            id="form-demora"
            noValidate
            validated={validated}
            onSubmit={handleSubmit}
          >
            <Form.Group as={Row} className="mb-3" controlId="form-demora-motivo">
              <Form.Label column sm="2">
                Motivo
              </Form.Label>
              <Col sm="10">
                <Form.Select
                  required
                  value={form.motivoId ?? ''}
                  onChange={(event) => {
                    setForm({...form, motivoId: event.target.value});
                  }}
                >
                  <option value=""></option>
                  {motivos.map((motivo, index) => {
                    return (
                      <option key={index} value={motivo.id}>
                        {motivo.descripcion}
                      </option>
                    );
                  })}
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                  Seleccione una opción
                </Form.Control.Feedback>
              </Col>
            </Form.Group>

            <Form.Group as={Row} className="mb-3" controlId="form-demora-horas">
              <Form.Label column sm="2">
                Horas
              </Form.Label>
              <Col sm="10">
                <Form.Control
                  type="number"
                  required
                  value={form.horas ?? ''}
                  min="1"
                  onChange={(event) => {
                    setForm({...form, horas: event.target.value});
                  }}
                />
                <Form.Control.Feedback type="invalid">
                  Este valor debe ser un número entero positivo
                </Form.Control.Feedback>
              </Col>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-secondary" onClick={handleClose}>
            Cancelar
          </Button>
          <Button
            form="form-demora"
            type="submit"
            variant="primary"
          >
            Cargar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
