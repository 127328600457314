import { useState } from 'react';
import arrowUp from 'assets/arrow-up.svg';

export default function Accordion({ title, children }) {
  const [open, setOpen] = useState(false);

  return (
    <section className="mt-4">
      <section
        style={{ backgroundColor: '#E2E2E2', cursor: 'pointer' }}
        className="d-flex text-black shadow-sm rounded align-items-center py-2"
        onClick={() => setOpen((prevState) => !prevState)}
      >
        <img
          style={{ transform: !open && 'rotate(180deg)', width: '16px' }}
          className="ms-3 me-4 cursor-pointer"
          src={arrowUp}
          alt=""
        />

        <h2 className="text-bg-dark m-0 fw-bold">{title}</h2>
      </section>

      <section className={`${open ? 'd-flex' : 'd-none'} flex-column gap-3 mt-3`}>
        {children}
      </section>
    </section>
  );
}
