import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Button, Modal } from 'react-bootstrap';
import TablePagination from 'components/TablePagination';
import Page from 'components/Page';
import Loading from 'components/Loading';
import useAxios from 'hooks/useAxios';

const columns = [
  {
    field: 'distribuidor.nombre',
    headerName: 'Distribuidor',
  },
  {
    field: 'distribuidor.marca',
    headerName: 'Marca',
  },
  {
    field: 'codigo',
    headerName: 'Nº de Dest.',
  },
  {
    field: 'nombre',
    headerName: 'Destinatario',
  },
  {
    field: 'georreferenciacion',
    headerName: 'Georreferenciación',
  },
  {
    field: 'direccion',
    headerName: 'Dirección',
  },
  {
    field: 'localidad.codigo_postal',
    headerName: 'CP',
  },
  {
    field: 'localidad.provincia',
    headerName: 'Provincia',
  },
  {
    field: 'localidad.nombre',
    headerName: 'Localidad',
  },
  {
    field: 'distribuidor.codigo',
    headerName: 'Distribuidor (SAP)',
  },
];
export default function Destinatarios() {
  const axios = useAxios();
  const navigate = useNavigate();
  const [destinatarios, setDestinatarios] = useState([]);
  const [destinatario, setDestinatario] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [erroresImportacion, setErroresImportacion] = useState([]);
  const [importacionExitosa, setImportacionExitosa] = useState(false);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    setIsLoading(true);

    try {
      const response = await axios.get('/logistica/destinatarios');
      const data = response.data.map((x) => {
        return {
          ...x,
          georreferenciacion: `${x?.geolocalizacion?.lat || 0},${
            x?.geolocalizacion?.lng || 0
          }`,
        };
      });

      setDestinatarios(data);
    } catch (error) {
      console.error(error);
    }

    setIsLoading(false);
  };

  const handleEdit = (destinatario) => {
    navigate('/destinatarios/editar', {
      state: { destinatario },
    });
  };

  const handleDelete = (destinatario) => {
    setDestinatario(destinatario);
  };

  const handleCloseDeleteModal = () => {
    setDestinatario(null);
  };

  const handleDeleteConfirmation = async () => {
    try {
      const response = await axios.delete(
        `/logistica/destinatarios/${destinatario.id}`,
      );

      setDestinatario(null);
      fetchData();
    } catch (error) {
      console.error(error);
    }
  };

  const handleImport = async (file) => {
    setErroresImportacion([]);
    setImportacionExitosa(false);

    const formData = new FormData();
    formData.append('file', file);

    try {
      const response = await axios.post(
        '/logistica/destinatarios/importar',
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );

      const errores = response.data.filter((item) => item.error);

      if (errores.length === 0) {
        setImportacionExitosa(true);
      } else {
        setErroresImportacion(errores);
      }

      fetchData();
    } catch (error) {
      console.error(error);
      alert('Hubo un error al realizar la importación');
    }
  };

  return (
    <>
      <Page>
        {isLoading && <Loading />}

        <Page.Content>
          <Page.Header title="Destinatarios">
            <Link to="/destinatarios/crear">
              Añadir Nuevo Destinatario
            </Link>
          </Page.Header>

          {erroresImportacion.length > 0 && (
            <div className="alert alert-danger">
              <p>Las siguientes filas no pudieron ser importadas:</p>
              <ul>
                {erroresImportacion.map((item) => (
                  <li key={item.rowNumber}>
                    Fila {item.rowNumber}: {item.error}
                  </li>
                ))}
              </ul>
            </div>
          )}

          {importacionExitosa && (
            <div className="alert alert-success">
              La importación finalizó exitosamente
            </div>
          )}

          <div className="flex-grow-1">
            <TablePagination
              columns={columns}
              data={destinatarios}
              onDelete={handleDelete}
              onEdit={handleEdit}
              onImport={handleImport}
              pagination
            />
          </div>
        </Page.Content>
      </Page>

      {destinatario && (
        <Modal
          onHide={handleCloseDeleteModal}
          show={destinatario}
          backdrop
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>Eliminar destinatario</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Se eliminará el destinatario <strong>{destinatario.nombre}</strong>. ¿Está seguro que desea eliminar este destinatario?
          </Modal.Body>
          <Modal.Footer>
            <Button variant="outline-secondary" onClick={handleCloseDeleteModal}>
              Cancelar
            </Button>
            <Button variant="danger" onClick={handleDeleteConfirmation}>
              Eliminar
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </>
  );
};
