import { Routes, Route } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import Mapa from 'pages/Mapa';
import Transporte from 'pages/Transporte';
import Entregas from 'pages/Entregas';
import DownloadEntrega from 'pages/DownloadEntrega';
import Transportistas from 'pages/Transportistas';
import TransportistasForm from 'pages/TransportistasForm';
import Destinatarios from 'pages/Destinatarios';
import DestinatariosForm from 'pages/DestinatariosForm';
import Mensajes from 'pages/Mensajes';
import MensajesDetalle from 'pages/MensajesDetalle';
import Dashboard from 'pages/Dashboard';
import NotFound from 'pages/NotFound';
import ProtectedRoute from 'auth/ProtectedRoute';
import Loading from 'components/Loading';

export default function App() {
  const { isLoading, logout } = useAuth0();

  if (isLoading) {
    return <Loading />;
  }

  return (
    <Routes>
      <Route path="/" element={<ProtectedRoute component={Mapa} />} />
      <Route path="/transportes/:transporteId" element={<ProtectedRoute component={Transporte} />} />
      <Route path="/entregas" element={<ProtectedRoute component={Entregas} />} />
      <Route path="/entregas/:codigoEntrega/download" element={<ProtectedRoute component={DownloadEntrega} />} />
      <Route path="/destinatarios" element={<ProtectedRoute component={Destinatarios} />} />
      <Route path="/destinatarios/crear" element={<ProtectedRoute component={DestinatariosForm} />} />
      <Route path="/destinatarios/editar" element={<ProtectedRoute component={DestinatariosForm} />} />
      <Route path="/transportistas" element={<ProtectedRoute component={Transportistas} />} />
      <Route path="/transportistas/crear" element={<ProtectedRoute component={TransportistasForm} />} />
      <Route path="/transportistas/editar" element={<ProtectedRoute component={TransportistasForm} />} />
      <Route path="/mensajes" element={<ProtectedRoute component={Mensajes} />} />
      <Route path="/mensajes/:entregaId" element={<ProtectedRoute component={MensajesDetalle} />} />
      <Route path="/dashboard" element={<ProtectedRoute component={Dashboard} />} />
      <Route path="*" element={NotFound} />
    </Routes>
  );
};
