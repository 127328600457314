import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import ChatInput from './ChatInput';
import ChatMessage from './ChatMessage';

const ChatHeader = styled.div`
  &.chat-title {
    display: flex !important;
    justify-content: space-between;
    width: auto;
    font-weight: bolder;
    border-bottom: #ccc solid 1px;
    padding: 0 20px 10px 20px;
    margin: 0 -20px 10px -20px;
  }
  .chat-title {
    &--left {
      font-size: 16px !important;
      color: #209bdb;
    }
    &--right {
      font-size: 16px !important;
      color: #333;
    }
  }
`;

const ChatBody = styled.div`
  display: flex;
  flex-direction: column;
`;

const ChatBodyMessages = styled.div`
  flex-basis: 100%;
  max-height: 430px;
  overflow-y: scroll;
`;

const ChatBodyInput = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
`;

const ChatWrapper = styled.div`
  background: #fff;
  width: 100%;
  height: 100%;
  padding: 20px;
  display: flex;
  flex-direction: column;
`;

const AlwaysScrollToBottom = () => {
  const elementRef = useRef();
  useEffect(() => elementRef.current.scrollIntoView());
  return <div ref={elementRef} />;
};

const Chat = ({ mensajes, setMensajeEnviado, motivo }) => {
  const [mensaje, setMensaje] = useState('');

  return (
    <ChatWrapper>
      <ChatHeader className="chat-title">
        <span className="chat-title--left">Customer Service</span>
        <span className="chat-title--right">
          {motivo && `Motivo: ${motivo}`}
        </span>
      </ChatHeader>
      <ChatBody>
        <ChatBodyMessages className="chat-body__messages">
          {mensajes &&
            mensajes.map((mensaje) => {
              return <ChatMessage mensaje={mensaje} key={mensaje.id} />;
            })}
          <AlwaysScrollToBottom />
        </ChatBodyMessages>
        <ChatBodyInput className="chat-body__input">
          <ChatInput
            mensajes={mensajes}
            setMensajeInput={setMensaje}
            setMensajeEnviado={setMensajeEnviado}
            mensaje={mensaje}
          />
        </ChatBodyInput>
        {mensaje.length > 100 && (
          <p className="text-red-500">No puede exceder los 100 caracteres</p>
        )}
      </ChatBody>
    </ChatWrapper>
  );
};

export default Chat;
