import styled from 'styled-components';
import { useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import MensajesIcon from 'assets/mensajes.svg';
import useAxios from 'hooks/useAxios';

const StyledInput = styled.textarea`
  box-shadow: 0 0 13px rgba(0, 0, 0, 0.02);
  border-radius: 30px;
  font-family: inherit;
  font-size: 18px;
  background-color: #fff;
  padding: 10px 20px 10px 20px;
  flex: 1;
  border-color: #ccc solid 2px;
  resize: none;
  letter-spacing: 0.3px;
  width: 100%;
  border: solid 2px #ccc;
  max-height: 50px;
  overflow: hidden;

  &:focus {
    outline: none;
  }

  ::-webkit-input-placeholder {
    color: '#B4B4B4';
  }

  ::placeholder {
    color: '#B4B4B4';
  }
`;

const StyledButton = styled.button`
  height: 55px;
  width: 55px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  flex: 0 0 64px;
  margin-left: 4%;
  color: white;
  border: none;
  cursor: pointer;
  background: #294ebf;
  transition: ease-in-out all 0.5s;

  &:hover {
    background: #092e9f;
  }

  :disabled {
    background-color: #ccc;
    pointer-events: none;
  }
`;

const StyledWarning = styled.span`
  font-weight: normal;
  line-height: 16px;
  color: #ff4b4b;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: -50px;
  left: 0;
  margin: 20px 0 0 4%;

  &::before {
    color: #ff4b4b;
    font-size: 19px;
    margin-right: 8px;
  }

  & + textarea {
    border: 1px solid #ff4b4b;
  }
`;

const Input = ({ setMensajeEnviado, setMensajeInput, mensaje }) => {
  const axios = useAxios();
  const inputRef = useRef(null);
  const { entregaId } = useParams();
  const [isSending, setIsSending] = useState(false);

  const handleClick = async () => {
    setIsSending(true);

    try {
      await axios.post(`/logistica/entregas/${entregaId}/mensajes`, {
        texto: mensaje,
      });

      setMensajeEnviado(true);
    } catch (error) {
      console.error(error);
    }

    setIsSending(false);
    setMensajeInput('');
    inputRef.current.focus();
  };

  return (
    <>
      {mensaje.length >= 100 && <StyledWarning />}

      <StyledInput
        placeholder="Ingrese su  mensaje"
        required
        onChange={(e) => setMensajeInput(e.target.value)}
        value={mensaje}
        autoFocus
        ref={inputRef}
      />

      <StyledButton
        onClick={handleClick}
        disabled={isSending || mensaje.length === 0 || mensaje.length >= 100}
      >
        <img src={MensajesIcon} alt="MensajesIcon" />
      </StyledButton>
    </>
  );
};

export default Input;
