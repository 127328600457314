const EstadoEntregaCellRenderer = (props) => {
  const ESTADOS = {
    pendiente: (
      <span
        style={{
          padding: '5px 15px',
          borderRadius: '3px',
          color: 'white',
          backgroundColor: '#3e3e3e',
          fontWeight: 'bold',
        }}
      >
        Pendiente
      </span>
    ),

    cancelada: (
      <span
        style={{
          padding: '5px 15px',
          borderRadius: '3px',
          color: 'white',
          backgroundColor: '#e91e63',
          fontWeight: 'bold',
        }}
      >
        Cancelado
      </span>
    ),

    encurso: (
      <span
        style={{
          padding: '5px 15px',
          borderRadius: '3px',
          color: 'white',
          backgroundColor: '#0db030',
          fontWeight: 'bold',
        }}
      >
        En Curso
      </span>
    ),

    entregado: (
      <span
        style={{
          padding: '5px 15px',
          borderRadius: '3px',
          color: 'white',
          backgroundColor: '#b4b4b4',
          fontWeight: 'bold',
        }}
      >
        Entregado
      </span>
    ),

    rechazado: (
      <span
        style={{
          padding: '5px 15px',
          borderRadius: '3px',
          color: 'white',
          backgroundColor: '#ff5722',
          fontWeight: 'bold',
        }}
      >
        Rechazado
      </span>
    ),
  };

  return ESTADOS[props.value] ?? props.value;
};

export default EstadoEntregaCellRenderer;
