import styled from 'styled-components';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';

dayjs.extend(relativeTime);

const StyledMessage = styled.div`
  letter-spacing: 0.3px;
  padding: 21px;
  max-width: 334px;
  font-weight: normal;
  box-shadow: 0 0 13px rgba(0, 0, 0, 0.02);
  display: flex;
  flex-direction: column;
  margin-bottom: 18px;
  border-radius: 40px 40px 50px 50px;
  min-width: 60%;
  border-bottom-right-radius: ${(props) => (props.fromMe ? '0' : '50')};
  border-bottom-left-radius: ${(props) => (props.fromMe ? '50' : '0')};
  background-color: ${(props) => (props.fromMe ? '#ddfebc' : '#dadada')};
  align-items: ${(props) => (props.fromMe ? 'flex-end' : 'flex-start')};
  text-align: ${(props) => (props.fromMe ? 'right' : 'left')};
  margin-left: ${(props) => (props.fromMe ? 'auto' : '')};

  p {
    margin-bottom: 2px;
  }

  &::before {
    color: #fff;
  }
`;

const StyledTitle = styled.p`
  font-weight: bold;
  font-size: 13px;
  line-height: 17px;
  padding-bottom: 5px;
`;

const StyledText = styled.p`
  line-height: 20px;
  max-width: 100%;
  overflow: hidden;
`;

const StyledDate = styled.p`
  line-height: 16px;
  padding: 8px 3px;
  width: 100%;
  text-align: right !important;
`;

const Message = ({ mensaje }) => {
  return (
    <StyledMessage fromMe={mensaje.email}>
      <StyledTitle>{mensaje.email}</StyledTitle>
      <StyledText>{mensaje.texto}</StyledText>
      <StyledDate>{`${dayjs().to(dayjs(mensaje.creado_el))} ${dayjs(
        mensaje.creado_el,
      ).format('HH:mm')}`}</StyledDate>
    </StyledMessage>
  );
};

export default Message;
