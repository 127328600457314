import { useState, useEffect } from 'react';

export default function FilterOptions({ placeholder, options, selectedOptions, setSelectedOptions }) {
  const namespace = Date.now();
  const [filteredOptions, setFilteredOptions] = useState([]);
  const [search, setSearch] = useState('');

  const handleSearchChange = (e) => {
    setSearch(e.target.value);
  };

  const handleSelectAll = () => {
    setSelectedOptions(options.map((item) => item.key));
  };

  const handleSelectNone = () => {
    setSelectedOptions([]);
  };

  const handleOptionChange = (option) => {
    const optionIndex = selectedOptions.indexOf(option.key);

    if (optionIndex === -1) {
      selectedOptions.push(option.key);
    } else {
      selectedOptions.splice(optionIndex, 1);
    }

    setSelectedOptions([...selectedOptions]);
  };

  useEffect(() => {
    if (search.length > 0) {
      setFilteredOptions(
        options.filter(
          (option) => String(option.value ?? '').toLowerCase().includes(search.toLocaleLowerCase())
        )
      );
    } else {
      setFilteredOptions([...options]);
    }
  }, [search]);

  return (
    <div className="d-flex flex-column">
      <input
        className="form-control"
        type="search"
        onChange={handleSearchChange}
        name="search"
        value={search}
        placeholder={placeholder}
      />

      <div className="my-2">
        <button
          type="button"
          className="btn btn-sm btn-light me-2"
          onClick={handleSelectAll}
        >
          Seleccionar todo
        </button>
        <button
          type="button"
          className="btn btn-sm btn-light"
          onClick={handleSelectNone}
        >
          Deseleccionar todo
        </button>
      </div>

      <div
        className="d-flex flex-column overflow-auto px-2"
        style={{ height: '200px' }}
      >
        {filteredOptions.map((option, index) => (
          <div className="form-check my-2" key={option.key}>
            <input
              className="form-check-input"
              type="checkbox"
              id={`checkbox-${namespace}-${index}`}
              checked={selectedOptions.includes(option.key)}
              onChange={() => handleOptionChange(option)}
            />
            <label
              className="form-check-label w-100"
              htmlFor={`checkbox-${namespace}-${index}`}
            >
              {option.value}
            </label>
          </div>
        ))}
      </div>
    </div>
  );
};
