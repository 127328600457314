import { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Loading from 'components/Loading';
import Filter from 'components/Filter';
import MapStats from 'components/MapStats';
import NavBar from 'components/NavBar';
import useMap from 'hooks/useMap';
import utils from 'utils';
import useAxios from 'hooks/useAxios';

export default function Mapa() {
  const axios = useAxios();
  const navigate = useNavigate();
  const { search } = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const [transportes, setTransportes] = useState([]);
  const [dashboard, setDashboard] = useState([]);
  const [selectedFilters, setSelectedFilters] = useState({});
  const [filters, setFilters] = useState({});
  const map = useMap();

  const fetchTransportes = async () => {
    setIsLoading(true);

    try {
      const response = await axios.get('/logistica/transportes/mapa', {
        params: selectedFilters
      });

      setTransportes(response.data.transportes);
      setDashboard(response.data.dashboard);
    } catch (error) {
      console.error(error);
    }

    setIsLoading(false);
  };

  const fetchFilters = async () => {
    try {
      const response = await axios.get('/logistica/transportes/filtros');
      setFilters(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  const handleApplyMarcaFilter = (selected) => {
    setSelectedFilters({ marcas: selected });
  };

  useEffect(() => {
    const params = new URLSearchParams(search);

    setSelectedFilters({ marcas: params.getAll('marcas') ?? [] });

    fetchFilters();
  }, []);

  useEffect(() => {
    if (map.isLoaded) {
      utils.dibujarTransportes(map, transportes, (transporte) => {
        navigate(`/transportes/${transporte.id}`);
      });
    }
  }, [transportes, map.isLoaded]);

  useEffect(() => {
    fetchTransportes();
  }, [selectedFilters]);

  return (
    <>
      {isLoading && <Loading />}

      <div className="position-relative" style={{ zIndex: 2 }}>
        <NavBar />
      </div>

      <div
        className="bg-white p-2 fw-bold rounded shadow-sm position-absolute"
        style={{ zIndex: 2, top: '80px', left: '20px' }}
      >
        {transportes.length > 0 ? (
          <span>Informacion en tiempo real (Híbrido)</span>
        ) : (
          <span className="text-danger">
            En este momento no tiene viajes despachados ni en curso
          </span>
        )}
      </div>

      {filters.marcas && (
        <div
          className="shadow-sm position-absolute"
          style={{ zIndex: 2, top: '80px', right: '20px' }}
        >
          <Filter
            className="shadow-sm py-2 px-4"
            defaultButtonClassName="btn-light"
            defaultTitle="Marcas"
            defaultSelectedOptions={selectedFilters.marcas ?? []}
            options={filters.marcas}
            onApply={handleApplyMarcaFilter}
          />
        </div>
      )}

      <div
        className="d-flex justify-content-end position-absolute"
        style={{ zIndex: 2, right: '50px', bottom: '20px' }}
      >
        {dashboard && <MapStats stats={dashboard} />}
      </div>

      <div className="mapa" ref={map.containerRef}></div>
    </>
  );
}
